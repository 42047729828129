export const MAX_CSV_COUNT = 50000
export const MAX_CABINET_CSV_COUNT = 25000

export const CSV_CUSTOM_METHODS = {
  inkassoCsvData: 'inkasso_csv_data',
}

export const CSV_COUNT_IN_COLUMN = 21
export const MAX_CSV_COLUMNS = 4
export const MAX_LENGTH_LIST = CSV_COUNT_IN_COLUMN * MAX_CSV_COLUMNS
