import React, { Component, Fragment } from 'react'
import { toJS } from 'mobx'
import AsyncSelect, { components } from 'react-select'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { isEqual } from 'utils/lodash.utils'

import { debounceEvent, trimStart } from 'utils/helpers.utils'
import { formatSelectCustomOptions } from 'utils/selectOptions.utils'

import { SELECT_WITH_LOAD_DELAY } from 'constants/forms.constants'
import { DEFAULT_PAGE } from 'constants/pagination.constants'
import { SIZES } from '@elo-kit/constants/general.constants'
import { LoadingSpinner } from '@elo-kit/components/loading-spinner/LoadingSpinner'
import { useI18n } from '@elo-kit/components/i18n/i18n'

import ClearIndicator from './SelectClearIndicator'
import DropdownIndicator from './SelectDropdownIndicator'

const propTypes = {
  initStore: PropTypes.shape({}).isRequired,
  formatSelectOptions: PropTypes.func,
  defaultOptions: PropTypes.arrayOf(PropTypes.shape({})),
  errorMessage: PropTypes.string,
  isInvalid: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ),
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  cashSelectedOption: PropTypes.bool,
  useCache: PropTypes.bool,
  searchLocally: PropTypes.bool,
  loadServerKey: PropTypes.string,
}

const defaultProps = {
  defaultOptions: [],
  disabled: false,
  formatSelectOptions: formatSelectCustomOptions,
  isInvalid: false,
  searchable: true,
  isClearable: false,
  cashSelectedOption: true,
  useCache: true,
  loadServerKey: 'id',
}

const Menu = (props) => {
  const { children, showLoadMore, handleLoadMode, isOptionsEqual, showNoOptions, loading, I18n } = props

  return (
    <Fragment>
      <components.Menu {...props}>
        <div>{children}</div>
        {showLoadMore && (
          <div onClick={handleLoadMode} className='elo-select__list-item elo-select__loading'>
            {I18n.t('react.shared.load_more')}
          </div>
        )}

        {(loading || (isOptionsEqual && !showNoOptions)) && (
          <LoadingSpinner loaderClassName='loading-spinner__loader--15-margin' size={SIZES.extraSmall} />
        )}
      </components.Menu>
    </Fragment>
  )
}

const NoOptionsMessage = (props) => {
  const { showNoOptions } = props

  if (!showNoOptions) {
    return null
  }

  return <components.NoOptionsMessage {...props} />
}

const DEFAULT_SELECTED_ITEM = [
  {
    value: '',
    label: '',
  },
]

const DEFAULT_TOTAL_COUNT = 1

class MultiAsyncSelectFieldContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      options: [],
      cachedOptions: [],
      cachedTotalCount: 0,
      searchValue: '',
      selectedItems: [],
      initialOptions: [],
      initialTotalCount: 0,
    }
  }

  loadOptionsWithDelay = debounceEvent((value) => {
    const { initStore, formatSelectOptions, requestParams, searchLocally } = this.props
    const { selectedItems } = this.state

    if (searchLocally && value && initStore.list) {
      const result = formatSelectOptions(initStore.list).filter(
        (item) =>
          item.value.toString().toLowerCase().includes(value.toLowerCase()) ||
          item.label.toLowerCase().includes(value.toLowerCase())
      )
      this.setOptions(result)
    } else if (value) {
      initStore
        .fetchList({
          query: value,
          page: DEFAULT_PAGE,
          ...requestParams,
        })
        .then(({ data }) => {
          const { list = [], totalCount } = data || {}
          const isEqualListAndOptions = isEqual(formatSelectOptions(data.list), selectedItems)

          this.setOptions(isEqualListAndOptions ? [] : formatSelectOptions(data.list))

          this.setCashedOptions(
            list.length && !isEqualListAndOptions ? formatSelectOptions(data.list) : DEFAULT_SELECTED_ITEM
          )
          this.setCashedTotalCounts(list.length && !isEqualListAndOptions ? totalCount : DEFAULT_TOTAL_COUNT)
        })
    }
  }, SELECT_WITH_LOAD_DELAY)

  componentDidMount() {
    const {
      initStore,
      pagination = {},
      formatSelectOptions,
      value = [],
      defaultOptions,
      expands,
      loadServerKey,
    } = this.props

    if (expands) {
      initStore.setExpands(expands)
    }

    pagination.page = DEFAULT_PAGE

    if (value.length) {
      initStore.fetchFullList({ [loadServerKey]: toJS(value) }).then(({ data, success }) => {
        if (success) {
          const { list = [] } = data || {}
          const selectedItems = formatSelectOptions(list) || {
            value: '',
            label: '',
          }

          this.setSelectedItems([...selectedItems])
        }
      })
    }
    if (defaultOptions) {
      this.setOptions([...defaultOptions])
    }
  }

  componentWillUnmount() {
    const { initStore, expands, pagination } = this.props

    if (expands) {
      initStore.resetExpands()
      pagination.page = DEFAULT_PAGE
    }
  }

  setOptions = (options) => this.setState({ options })
  setCashedOptions = (cachedOptions) => this.setState({ cachedOptions })
  setCashedTotalCounts = (cachedTotalCount) => this.setState({ cachedTotalCount })
  setSearchValue = (searchValue) => this.setState({ searchValue })
  setSelectedItems = (selectedItems) => this.setState({ selectedItems })
  setInitialOptions = (initialOptions) => this.setState({ initialOptions })
  setInitialTotalCount = (initialTotalCount) => this.setState({ initialTotalCount })

  fetchListOnOpen = async () => {
    const { initStore, pagination = {}, defaultOptions = [], requestParams, formatSelectOptions } = this.props

    pagination.page = DEFAULT_PAGE

    const resp = initStore.fetchList ? await initStore.fetchList(requestParams) : {}
    const { data = {}, success } = resp || {}

    if (success) {
      const { list = [], totalCount } = data || {}
      const formattedOptions = [...defaultOptions, ...formatSelectOptions(list)]
      this.setOptions(formattedOptions)
      this.setCashedOptions(formattedOptions)
      this.setCashedTotalCounts(totalCount)

      // This is for set data after first fetch and show always when string is empty
      this.setInitialOptions(formattedOptions)
      this.setInitialTotalCount(totalCount)
    }
  }

  handleSelectOpen = () => {
    const { useCache, disabled, requestParams } = this.props

    const { cachedOptions } = this.state

    if (!disabled && (!useCache || !cachedOptions.length)) {
      this.fetchListOnOpen(requestParams)
    } else {
      this.setOptions(cachedOptions)
      this.setSearchValue('')
    }
  }

  handleLoadMode = () => {
    const {
      initStore: { fetchList },
      formatSelectOptions,
      pagination,
    } = this.props
    const { options, searchValue, cachedOptions } = this.state

    pagination.page += 1
    fetchList({ query: searchValue }).then((resp) => {
      const { data: { list = [] } = {} } = resp || {}
      this.setOptions([...options, ...formatSelectOptions(list)])
      this.setCashedOptions([...cachedOptions, ...formatSelectOptions(list)])
    })
  }

  handleSearch = (option, inputValue) => {
    const { searchValue, cachedOptions } = this.state

    const trimmedValue = trimStart(inputValue)

    if (searchValue !== trimmedValue) {
      this.setState({ searchValue: trimmedValue }, () => {
        if (this.state.searchValue.length) {
          this.loadOptionsWithDelay(this.state.searchValue)
        } else {
          this.setOptions(cachedOptions)
        }
      })
    }

    const isOptionsEqual = isEqual(cachedOptions, DEFAULT_SELECTED_ITEM)

    if (!searchValue && !trimmedValue && isOptionsEqual) {
      this.onBlur()
    }

    return true
  }

  onFieldChange = (options) => {
    const { onChange } = this.props

    const formattedOptions = options.map((option) => option.value)

    this.setSelectedItems(options)

    onChange(formattedOptions)
  }

  onBlur = () => {
    const { initialOptions, initialTotalCount } = this.state

    this.setOptions(initialOptions)
    this.setCashedOptions(initialOptions)
    this.setCashedTotalCounts(initialTotalCount)
  }

  render() {
    const {
      initStore,
      filteredOptions,
      className,
      containerClassName,
      disabled,
      searchable = true,
      classNamePrefix,
      placeholder = this.props.I18n.t('react.shared.select.placeholder'),
      isClearable,
      value = [],
      loading,
      pagination,
      I18n,
    } = this.props

    const { options, searchValue, cachedOptions, cachedTotalCount, selectedItems } = this.state

    const totalItems = searchValue.length ? pagination.total : cachedTotalCount

    const formattedValues = selectedItems.reduce((result, option) => {
      const hasExactMatch = !!selectedItems.find(({ value }) => String(value) === String(option.value))
      const convertedValue = toJS(value)

      const arrValue = Array.isArray(convertedValue) ? convertedValue : [convertedValue]

      const checkExactMatches = arrValue.some((item) => String(item) === String(option.value))

      const shouldPushOption = hasExactMatch ? checkExactMatches : value.includes(String(option.value))

      return shouldPushOption ? [...result, option] : result
    }, [])

    const showLoadMore = !!options.length && totalItems > options.length && !loading && !filteredOptions

    const showNoOptions = !loading && !options.length

    const isOptionsEqual = isEqual(cachedOptions, DEFAULT_SELECTED_ITEM)

    const fieldContainerClassNames = classNames('field elo-select-container', containerClassName, {
      'field--disabled': disabled,
    })

    const fieldClassNames = classNames('elo-select-field', className, {
      'elo-select-field__hide-option': isOptionsEqual,
      searchable,
    })

    const fieldClassNamePrefix = classNames(classNamePrefix || 'elo-select-field')

    return (
      <div className={fieldContainerClassNames}>
        <AsyncSelect
          isMulti
          cacheOptions
          defaultOptions
          placeholder={placeholder}
          onFocus={this.handleSelectOpen}
          filterOption={this.handleSearch}
          onChange={this.onFieldChange}
          options={options}
          value={formattedValues}
          classNamePrefix={fieldClassNamePrefix}
          className={fieldClassNames}
          isDisabled={disabled}
          isSearchable={searchable}
          isClearable={isClearable}
          onBlurResetsInput={false}
          onCloseResetsInput={false}
          autoload={false}
          onInputChange={cachedOptions.length ? this.handleSelectOpen : () => {}}
          components={{
            Menu: (props) => (
              <Menu
                initStore={initStore}
                loading={loading}
                showLoadMore={showLoadMore}
                handleLoadMode={this.handleLoadMode}
                isOptionsEqual={isOptionsEqual}
                showNoOptions={showNoOptions}
                I18n={I18n}
                {...props}
              />
            ),
            NoOptionsMessage: (props) => <NoOptionsMessage showNoOptions={showNoOptions} {...props} />,
            ClearIndicator,
            DropdownIndicator,
          }}
        />
      </div>
    )
  }
}

MultiAsyncSelectFieldContainer.displayName = 'MultiAsyncSelectField'
MultiAsyncSelectFieldContainer.propTypes = propTypes
MultiAsyncSelectFieldContainer.defaultProps = defaultProps

const MultiAsyncSelectField = (props) => {
  const I18n = useI18n()

  return <MultiAsyncSelectFieldContainer I18n={I18n} {...props} />
}
export default MultiAsyncSelectField
