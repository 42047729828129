import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import camelcase from 'camelcase'

import { createId } from '@elo-kit/utils/general.utils'

const defaultProps = {
  withHash: false,
  sectionClasses: '',
}

const propTypes = {
  withHash: PropTypes.bool,
  sectionClasses: PropTypes.string,
}

@observer
class SectionTabs extends Component {
  componentDidMount() {
    const { setActiveTab, withHash, locationHash } = this.props

    if (withHash && locationHash) {
      setActiveTab(locationHash.substr(1))
    }
  }

  setActiveSectionTab = (key) => {
    const { setActiveTab, withHash } = this.props

    if (withHash) {
      window.location.hash = `${key}`
    }
    setActiveTab(key)
  }

  render() {
    const {
      activeTab,
      tabs,
      section = [],
      sectionClasses,
      sectionTabsClassName,
      sectionTabsContentClassName,
    } = this.props
    const sectionTabsClasses = classNames('elo-section-tabs', sectionTabsClassName)

    return (
      <Fragment>
        <ul className={sectionTabsClasses}>
          {tabs.map((tab, index) => {
            const { key, label } = tab
            const tabClasses = classNames(
              'elo-section-tabs__tab-content',
              {
                'elo-section-tabs__tab-content--active': activeTab === key,
              },
              sectionTabsContentClassName
            )

            return (
              <li className='elo-section-tabs__tab' key={createId(key, index)} id={camelcase(label)}>
                <span className={tabClasses} onClick={() => this.setActiveSectionTab(key)}>
                  {label}
                  {section.includes(key) && (
                    <span className='elo-section-tabs__tab-content--warning'>
                      {sectionClasses && <i className={sectionClasses} />}
                    </span>
                  )}
                </span>
              </li>
            )
          })}
        </ul>
      </Fragment>
    )
  }
}

SectionTabs.displayName = 'SectionTabs'
SectionTabs.propTypes = propTypes
SectionTabs.defaultProps = defaultProps

export default SectionTabs
