import React from 'react'

import { useI18n } from '@elo-kit/components/i18n/i18n'

const SeparatorOptionLabel = (props) => {
  const { value, label } = props
  const I18n = useI18n()

  return (
    <div className='cashout-option-label'>
      <div>
        <div className='cashout-option-label__currency'>{label}</div>
        <div className='cashout-option-label__provider'>
          {value === 'comma'
            ? I18n.t('react.shared.separator_type.example.comma')
            : I18n.t('react.shared.separator_type.example.dot')}
        </div>
      </div>
    </div>
  )
}

export default SeparatorOptionLabel
