import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { formatDateTime } from '@elo-kit/utils/dateTime.utils'

import { DAY_FREQUENCY_LOCALES, DAY_VALUES, FREQUENCY_TYPES } from 'constants/csvLogs.constants'

import EloTable from 'ui/elo-table/EloTable'
import TableSortLabelWrap from 'ui/TableSortLabelWrap'
import { I18nContext } from '@elo-kit/components/i18n/i18n'
import { TooltipMenu } from '@elo-kit/components/elo-ui/tooltip-menu/TooltipMenu'

const getTableHead = (I18n) => [
  {
    label: I18n.t('react.cabinet.common.name'),
    sortingKey: 'name',
  },
  {
    label: I18n.t('react.common.type'),
    sortingKey: 'list_type',
  },
  {
    label: I18n.t('react.shared.common.day'),
    sortingKey: 'day',
  },
  {
    label: I18n.t('react.cabinet.product.created'),
    sortingKey: 'created_at',
  },
  {
    label: '',
  },
]

@inject('csvLogsStore')
@observer
class CsvScheduledReports extends Component {
  static contextType = I18nContext

  render() {
    const {
      csvLogsStore,
      csvLogsStore: { list },
      editItem,
      setItemToDelete,
      duplicateItem,
    } = this.props
    const I18n = this.context

    const getTableBody = list.map((item) => {
      const { id, customName, listType, schedule: { createdAt, dayType, dayValue } = {} } = item

      const tooltipMenuItems = [
        {
          show: true,
          label: 'far fa-trash-alt elo-btn-icon',
          action: () => setItemToDelete(id),
        },
        {
          show: true,
          label: 'far fa-clone elo-btn-icon',
          action: () => duplicateItem(id),
        },
        {
          show: true,
          label: 'fas fa-pencil-alt elo-btn-icon',
          action: () => editItem(id),
        },
      ]

      return (
        <tr key={id}>
          <td className='elo-table__title'>
            {customName || `${listType.toLowerCase()}-csv-${formatDateTime(createdAt)}`}
          </td>
          <td className='elo-table__text-gray'>
            <div className='elo-table__text-italic'>{DAY_FREQUENCY_LOCALES[dayType]}</div>
          </td>
          <td className='elo-table__text-gray'>{DAY_VALUES[dayValue]?.label[FREQUENCY_TYPES[dayType]]}</td>
          <td className='elo-table__text-gray date-time-field__table--modal'>{formatDateTime(createdAt)}</td>
          <td className='actions'>
            <TooltipMenu id={id} menuItems={tooltipMenuItems} iconMenu />
          </td>
        </tr>
      )
    })

    return (
      <EloTable initStore={csvLogsStore} search={false}>
        <EloTable.Head>
          <TableSortLabelWrap store={csvLogsStore} list={getTableHead(I18n)} />
        </EloTable.Head>
        <EloTable.Body>{getTableBody}</EloTable.Body>
      </EloTable>
    )
  }
}

export default CsvScheduledReports
