import React, { Component } from 'react'
import { observable, action, runInAction, makeObservable } from 'mobx'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import moment from 'moment'

import { I18nContext } from '@elo-kit/components/i18n/i18n'
import { SelectField } from '@elo-kit/components/form/select-field/SelectField'

import { TEXT_FIELD_AUTOCOMPLETE } from 'constants/forms.constants'
import { TIME_FILTER_KEYS, TIME_FILTER_OPTIONS } from 'constants/timeFilter.constants'
import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'

const propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.oneOf([TEXT_FIELD_AUTOCOMPLETE.on, TEXT_FIELD_AUTOCOMPLETE.off]),
  disabled: PropTypes.bool,
  initStore: PropTypes.shape({}),
  serverKeys: PropTypes.arrayOf(PropTypes.string),
}

const defaultProps = {
  serverKeys: ['from', 'till'],
  autocomplete: TEXT_FIELD_AUTOCOMPLETE.off,
  disabled: false,
}

@observer
class TimeFilter extends Component {
  @observable filterValue = TIME_FILTER_KEYS.allTime

  static contextType = I18nContext

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if (defaultValue) {
      runInAction(() => (this.filterValue = defaultValue))
      this.setFilterValue(defaultValue)
    }
  }

  @action setFilterValue = (option) => {
    const { initStore, serverKeys } = this.props
    this.filterValue = option
    let fromDate = ''
    let tillDate = new Date()
    const date = new Date()

    switch (option) {
      case TIME_FILTER_KEYS.today: {
        fromDate = new Date()
        break
      }
      case TIME_FILTER_KEYS.yesterday: {
        date.setDate(date.getDate() - 1)
        fromDate = date
        tillDate = date
        break
      }
      case TIME_FILTER_KEYS.threeDays: {
        date.setDate(date.getDate() - 3)
        fromDate = date
        break
      }
      case TIME_FILTER_KEYS.oneWeek: {
        date.setDate(date.getDate() - 7)
        fromDate = date
        break
      }
      case TIME_FILTER_KEYS.twoWeeks: {
        date.setDate(date.getDate() - 14)
        fromDate = date
        break
      }
      default:
        tillDate = ''
    }

    if (option == TIME_FILTER_KEYS.allTime) {
      initStore.resetFilters()
      initStore.applyFilters()
    } else {
      initStore.handleFilterChange(serverKeys[0], moment(fromDate).format(DATE_FORMATS.default))
      initStore.handleFilterChange(serverKeys[1], moment(tillDate).format(DATE_FORMATS.default))
      initStore.applyFilters()
    }
  }

  render() {
    const { className, disabled } = this.props
    const I18n = this.context

    const fieldClassNames = classNames('elo-time-filter', className)

    return (
      <div className={fieldClassNames}>
        <SelectField
          placeholder={I18n.t('react.cabinet.common.select.placeholder')}
          value={this.filterValue}
          options={TIME_FILTER_OPTIONS}
          onChange={this.setFilterValue}
          disabled={disabled}
        />
      </div>
    )
  }
}

TimeFilter.displayName = 'TimeFilter'
TimeFilter.propTypes = propTypes
TimeFilter.defaultProps = defaultProps

export default TimeFilter
