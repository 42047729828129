import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import { action, observable, makeObservable } from 'mobx'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { GET_API_URL } from 'utils/requests.utils'
import { createId } from '@elo-kit/utils/general.utils'
import { TableLoadingMask as LoadingMask } from '@elo-kit/components/loading-mask/LoadingMask'
import { CheckboxField } from '@elo-kit/components/form/checkbox-field/CheckboxField'
import { EloButton } from '@elo-kit/components/elo-button/EloButton'
import { I18nContext, useI18n } from '@elo-kit/components/i18n/i18n'

import { STATUSES } from 'constants/general.constants'

import Filter from 'ui/filter/Filter'
import NoData from 'ui/no-data/NoData'
import SearchField from 'ui/search-field/SearchField'
import Pagination from 'ui/pagination/Pagination'
import TimeFilter from 'ui/TimeFilter'
import { FilterGrouping } from 'ui/filter-grouping/FilterGrouping'

import SavedFilters from 'shared/screens/saved-filters/SavedFilters'

import CsvDownloadModal from './csv-download-modal/CsvDownloadModal'

import './table.scss'
import './table-navigation.scss'

const propTypes = {
  initStore: PropTypes.shape({}),
  children: PropTypes.node,
  filtersList: PropTypes.arrayOf(PropTypes.string),
  noDataText: PropTypes.node,
  search: PropTypes.bool,
  pagination: PropTypes.bool,
  csv: PropTypes.string,
  tableWithCheckbox: PropTypes.bool,
  tableWithoutMargin: PropTypes.bool,
  noDataIcon: PropTypes.bool,
  forceNoData: PropTypes.bool,
  withSavedFilters: PropTypes.bool,
  performScroll: PropTypes.bool,
  withLabelCreation: PropTypes.bool,
}

const defaultProps = {
  pagination: true,
  search: true,
  tableWithCheckbox: false,
  tableWithoutMargin: false,
  noDataIcon: true,
  forceNoData: false,
  withSavedFilters: false,
  initStore: {},
  showItemsPerPageField: true,
  pageToGoField: true,
  itemsNumberField: true,
  performScroll: true,
  withLabelCreation: false,
}

const Totals = ({ total, additional, amount }) => (
  <div className='elo-table__totals'>
    {amount && <div className='elo-table__totals--amount'>{amount}</div>}
    {additional && (
      <div className='elo-table__totals--additional'>
        <span>{additional}</span>
      </div>
    )}
    {total && <div className='elo-table__totals--total'>{total}</div>}
  </div>
)

@withRouter
@observer
class EloTable extends Component {
  @observable openCsvDownloadModal = false
  @observable openTaxCsvDownloadModal = false

  @action toggleCsvDownloadModal = () => {
    this.openCsvDownloadModal = !this.openCsvDownloadModal
  }

  @action toggleTaxCsvDownloadModal = () => {
    this.openTaxCsvDownloadModal = !this.openTaxCsvDownloadModal
  }

  static contextType = I18nContext

  static Head = (props) => {
    const { children, checkedAll, disabledCheckAll, handleCheckAll, additionalRows = false } = props

    return (
      <thead>
        <tr>
          {handleCheckAll && (
            <th>
              <CheckboxField
                id='checkedAll'
                checked={checkedAll}
                onChange={handleCheckAll}
                disabled={disabledCheckAll}
              />
            </th>
          )}
          {children}
        </tr>
        {!!additionalRows && additionalRows}
      </thead>
    )
  }

  static ColGroup = ({ widths = [] }) => (
    <colgroup>
      {widths.map((value, index) => (
        <col key={createId(value, index)} width={`${value}%`} />
      ))}
    </colgroup>
  )
  static Body = ({ children, embedBody = false }) => (embedBody ? children : <tbody>{children}</tbody>)
  static StatusCell = ({ children, status }) => {
    let className = 'elo-table__text-gray'

    if (status === STATUSES.active || status === STATUSES.success) {
      className = 'elo-table__text-green'
    }

    if (status === STATUSES.error) {
      className = 'elo-table__text-red'
    }

    if (status === STATUSES.pending || status === STATUSES.waiting || status === STATUSES.paused) {
      className = 'elo-table__text-orange'
    }

    return <td className={className}>{children}</td>
  }
  static StaticTable = (props) => {
    const I18n = useI18n()
    const {
      loading = false,
      showNoData,
      noDataText = I18n.t('react.cabinet.common.no_data'),
      compactTable,
      children,
      handlePaginationChange,
      total,
      page,
      per,
      lastRightAlignment = false,
      tableWithCheckbox,
      tableWithRadios,
      tableBordered,
      tableWithoutMargin,
      className,
      containerClassName,
    } = props

    const tableClasses = classNames(
      'elo-table',
      {
        'compact-table': compactTable,
        'compact-table--last-right-align': lastRightAlignment,
        'elo-table--with-checkboxes': tableWithCheckbox,
        'elo-table--table-with-radios': tableWithRadios,
        'table-bordered': tableBordered,
        'elo-table--no-margin': tableWithoutMargin,
      },
      className
    )
    const tableContainerClasses = classNames('elo-table-container', containerClassName)

    return (
      <Fragment>
        <div className={tableContainerClasses}>
          <table className={tableClasses}>{children}</table>
        </div>
        {!loading && showNoData && <NoData message={noDataText} />}
        {!!handlePaginationChange && (
          <Pagination
            pageToGoField
            itemsNumberField
            showItemsPerPageField
            initStore={{
              handlePaginationChange,
              pagination: {
                total,
                page,
                per,
              },
            }}
          />
        )}
        {loading && <LoadingMask />}
      </Fragment>
    )
  }

  constructor(props) {
    super(props)

    makeObservable(this)

    this.table = React.createRef()
    this.tableContainer = React.createRef()
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const I18n = this.context
    const {
      children,
      csv,
      taxCsv,
      datevTaxCsv,
      datevTaxCsvLabel,
      datevHeaderCsvKeys,
      datevInitStore,
      csvConfig,
      csvParams,
      filtersList,
      filterStores,
      initStore,
      noDataText = I18n.t('react.cabinet.common.no_data'),
      withCustomFields,
      useCustomLoading,
      pagination,
      search,
      headerKeys,
      headerCsvKeys,
      tableHeaders,
      timeFilter,
      timeFilterDefaultValue,
      taxCsvLabel,
      handleOverlayButton,
      itemsPerPageOptions,
      tableWithCheckbox,
      tableWithoutMargin,
      tableModalScroll,
      refer = this.tableContainer,
      rightButton,
      timezone,
      tableWithRadios,
      noDataIcon,
      forceNoData,
      withSavedFilters,
      applyFilters,
      withCustomLoading,
      fetchSummary,
      pageToGoField = true,
      newCsvLayout,
      scrollIntoView = false,
      fastFilters,
      showItemsPerPageField,
      itemsNumberField,
      performScroll,
      history: {
        location: { hash: locationHash },
      },
      withLabelCreation,
      toggleCreationLabelModal,
      withoutLoading,
      selectedLabelIds,
      setSelectedLabelIds,
      applySelectedLabels,
      lastColumnStick,
    } = this.props

    const { csvQueryParams, list, loading, customLoading, pagination: storePagination = {} } = initStore

    const tableChildren = React.Children.map(children, (child) => React.cloneElement(child))
    const isLoading = useCustomLoading ? customLoading : loading
    const scrollCurrent = performScroll && (scrollIntoView ? this.table : refer)

    const tableContainer = classNames('elo-table-container', {
      'elo-table-container--table-with-checkboxes': tableWithCheckbox,
      'elo-table-container--one-screen-modal': tableModalScroll,
      'elo-table-container--table-with-radios': tableWithRadios,
      'elo-table-container--with-x-axis-scrollbar': this.table?.current?.clientWidth > refer?.current?.clientWidth,
    })

    const tableClasses = classNames('elo-table', {
      'elo-table--with-checkboxes': tableWithCheckbox,
      'elo-table--no-margin': tableWithoutMargin,
      'elo-table--last-column-stick': lastColumnStick,
    })

    return (
      <Fragment>
        <div className='elo-table-navigation'>
          {!!handleOverlayButton && !isLoading && handleOverlayButton()}
          <div className='elo-table-navigation__left-block'>
            {filtersList && (
              <Fragment>
                <Filter
                  fetchOptionsStores={filterStores}
                  {...{
                    filtersList,
                    initStore,
                    timezone,
                    withSavedFilters,
                    applyFilters,
                  }}
                />
                {withSavedFilters && (
                  <SavedFilters initStore={initStore} responsive={handleOverlayButton} fetchSummary={fetchSummary} />
                )}
                {withLabelCreation && (
                  <FilterGrouping
                    toggleCreationLabelModal={toggleCreationLabelModal}
                    selectedLabelIds={selectedLabelIds}
                    setSelectedLabelIds={setSelectedLabelIds}
                    applySelectedLabels={applySelectedLabels}
                  />
                )}
                {fastFilters && fastFilters()}
              </Fragment>
            )}
            {timeFilter && <TimeFilter initStore={initStore} defaultValue={timeFilterDefaultValue} />}
          </div>
          <div className='elo-table-navigation__right-block'>
            {search && <SearchField initStore={initStore} />}
            {csv && (
              <EloButton download href={GET_API_URL(csv, { ...csvQueryParams }, false)}>
                {I18n.t('react.common.csv')}
              </EloButton>
            )}
            {rightButton && rightButton()}
            {csvConfig && (
              <CsvDownloadModal
                tableHeaders={tableHeaders}
                headerKeys={headerKeys}
                params={csvParams}
                storePagination={storePagination}
                initStore={initStore}
                withCustomFields={withCustomFields}
                newCsvLayout={newCsvLayout}
                locationHash={locationHash}
              />
            )}
            {taxCsv && csvConfig && (
              <CsvDownloadModal
                taxCsvLabel={taxCsvLabel}
                headerKeys={headerCsvKeys}
                tableHeaders={tableHeaders}
                params={taxCsv}
                storePagination={storePagination}
                initStore={initStore}
                withCustomFields={withCustomFields}
                newCsvLayout={newCsvLayout}
                locationHash={locationHash}
              />
            )}
            {datevTaxCsv && csvConfig && (
              <CsvDownloadModal
                taxCsvLabel={datevTaxCsvLabel}
                headerKeys={datevHeaderCsvKeys}
                tableHeaders={tableHeaders}
                params={datevTaxCsv}
                storePagination={storePagination}
                initStore={datevInitStore}
                withCustomFields={withCustomFields}
                newCsvLayout={newCsvLayout}
                locationHash={locationHash}
              />
            )}
          </div>
        </div>
        {/* TODO: Set id only for products list table */}
        <div className={tableContainer} ref={refer} id='products-table'>
          <table className={tableClasses} ref={this.table}>
            {tableChildren}
          </table>
        </div>
        {((!isLoading && !list?.length && noDataIcon) || forceNoData) && <NoData message={noDataText} />}
        {pagination && (
          <Pagination
            pageToGoField={pageToGoField}
            itemsNumberField={itemsNumberField}
            showItemsPerPageField={showItemsPerPageField}
            showItemsPerPageOptions={itemsPerPageOptions}
            initStore={initStore}
            refToScroll={scrollCurrent}
            scrollToCurrent={scrollIntoView}
            selectedLabelIds={selectedLabelIds}
          />
        )}

        {!withoutLoading && ((withCustomLoading && customLoading) || isLoading) && <LoadingMask />}
      </Fragment>
    )
  }
}

EloTable.displayName = 'EloTable'
EloTable.propTypes = propTypes
EloTable.defaultProps = defaultProps
EloTable.Totals = Totals

export default EloTable
