import React, { Component } from 'react'
import { observable, action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  MAX_CSV_COUNT,
  MAX_CABINET_CSV_COUNT,
  MAX_LENGTH_LIST,
  MAX_CSV_COLUMNS,
  CSV_COUNT_IN_COLUMN,
} from 'constants/csv.constants'
import { ACTIVE_PROFILES } from 'constants/profile.constants'

import { CheckboxField } from '@elo-kit/components/form/checkbox-field/CheckboxField'
import { EloButton } from '@elo-kit/components/elo-button/EloButton'
import { I18nContext } from '@elo-kit/components/i18n/i18n'
import { createId } from '@elo-kit/utils/general.utils'
import { profile } from 'utils/profileHelper.utils'

import './checkbox-list.scss'

const propTypes = {
  list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  download: PropTypes.func,
  downloadDisabled: PropTypes.bool,
  notFormatted: PropTypes.bool,
}

const defaultProps = {
  notFormatted: true,
}

const CheckboxListOptions = ({ list, onChange, getCheckboxId, checkAll }) => (
  <div className='checkbox-list__columns--options'>
    {list.map((item, index) => {
      const { label, checked, key } = item

      const selectAllCheckbox = key === 'select_all'

      return (
        <div key={createId(label, index)}>
          <CheckboxField
            label={label}
            id={getCheckboxId(item)}
            onChange={selectAllCheckbox ? checkAll : onChange}
            checked={checked}
          />
        </div>
      )
    })}
  </div>
)

@observer
class CheckboxList extends Component {
  static contextType = I18nContext

  @observable checkedList = []

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { notFormatted, list } = this.props
    const checkedList = notFormatted
      ? list.filter(this.getCheckboxId).map((item) => ({
          ...item,
          checked: true,
        }))
      : list
    this.setCheckedList(checkedList)
  }

  @action onChange = (event, checked) => {
    const { handleOnChange, newCsvLayout, list } = this.props
    const csvCheckedList = newCsvLayout ? list : this.checkedList

    this.checkedList = csvCheckedList.map((item) => {
      if (String(this.getCheckboxId(item)) === String(event.target.id)) {
        item.checked = checked
      }
      return item
    })
    if (handleOnChange) {
      handleOnChange(this.checkedList)
    }
  }

  @action checkAll = (event, checked) => {
    const { handleOnChange } = this.props
    const checkedList = this.checkedList.map((item) => ({
      ...item,
      checked: checked,
    }))
    this.setCheckedList(checkedList)

    if (handleOnChange) {
      handleOnChange(checkedList)
    }
  }

  @action setCheckedList = (list) => (this.checkedList = list)

  getCheckboxId = (item) => {
    const { checkboxIdKeys } = this.props
    for (const key of checkboxIdKeys) {
      if (item[key]) return item[key]
    }
  }

  getChunksArray = (inputArray, perChunk) =>
    inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

  getCountColumns = (listLength, count) => Math.ceil(listLength / count)

  render() {
    const { onSubmit, submitLabel, downloadDisabled, newCsvLayout, list } = this.props

    const I18n = this.context
    const csvCheckedList = newCsvLayout ? list : this.checkedList
    const checkedAmount = csvCheckedList.filter((item) => item.checked).length

    const isSeller = profile.profileType === ACTIVE_PROFILES.seller
    const maxCount = isSeller ? MAX_CABINET_CSV_COUNT : MAX_CSV_COUNT

    const countCsvColumns =
      list.length > MAX_LENGTH_LIST ? MAX_CSV_COLUMNS : this.getCountColumns(list.length, CSV_COUNT_IN_COLUMN)
    const csvColumnsArray = [...Array(countCsvColumns).keys()]

    const checkedList = [
      {
        checked: checkedAmount === list.length,
        key: 'select_all',
        label: I18n.t('react.common.select_all'),
      },
      ...list,
    ]

    const chunkedCount =
      list.length > MAX_LENGTH_LIST ? this.getCountColumns(list.length, MAX_CSV_COLUMNS) : CSV_COUNT_IN_COLUMN
    const chunkedCheckboxArray = this.getChunksArray(checkedList, chunkedCount)

    const checkboxListClasses = classNames('checkbox-list__columns', {
      'checkbox-list__columns--stretched': countCsvColumns > 2,
    })

    return (
      <div className='checkbox-list'>
        {!newCsvLayout && (
          <>
            <div className='checkbox-list--select-all'>
              <CheckboxField
                label={I18n.t('react.common.select_all')}
                onChange={this.checkAll}
                checked={checkedAmount === this.checkedList.length}
              />
            </div>
            <div className='fields-container__horisontal-separator fields-container__horisontal-separator--bottom-20' />
          </>
        )}
        {newCsvLayout ? (
          <div className={checkboxListClasses}>
            {csvColumnsArray.map((item) => (
              <CheckboxListOptions
                key={item}
                list={chunkedCheckboxArray[item]}
                onChange={this.onChange}
                checkAll={this.checkAll}
                getCheckboxId={this.getCheckboxId}
              />
            ))}
          </div>
        ) : (
          <>
            {this.checkedList.map((item, index) => {
              const { label, checked } = item
              return (
                <div key={createId(label, index)}>
                  <CheckboxField
                    label={label}
                    id={this.getCheckboxId(item)}
                    onChange={this.onChange}
                    checked={checked}
                  />
                </div>
              )
            })}
          </>
        )}
        {onSubmit && (
          <EloButton onClick={() => onSubmit(this.checkedList)} disabled={!checkedAmount || downloadDisabled}>
            {submitLabel}
          </EloButton>
        )}
        {downloadDisabled && (
          <div style={{ color: 'red' }}>{I18n.t('react.shared.max_count_csv', { max_count: maxCount })}</div>
        )}
      </div>
    )
  }
}

CheckboxList.propTypes = propTypes
CheckboxList.defaultProps = defaultProps

export default CheckboxList
