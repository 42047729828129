import React from 'react'
import { PROFILE_LANGUAGE } from 'constants/profile.constants'

export const CSV_DOWNLOAD_TYPE = {
  list: 'list',
  custom: 'custom',
}

export const CSV_LOG_STATUSES_LABEL = {
  recent: I18n.t('react.cabinet.statuses.recent'),
  waiting: I18n.t('react.cabinet.statuses.waiting'),
  processed: I18n.t('react.cabinet.statuses.processed'),
  error: I18n.t('react.shared.state.error'),
  no_data: I18n.t('react.cabinet.statuses.no_data'),
}

export const CSV_LOG_STATUSES = {
  recent: 'recent',
  waiting: 'waiting',
  processed: 'processed',
}

export const SELLER_LIST_TYPE_LABEL = {
  products: I18n.t('react.shared.csv_log.list_type.products'),
  transfers: I18n.t('react.shared.csv_log.list_type.transfers'),
  datev_transfers: I18n.t('react.shared.csv_log.list_type.transfers'),
  payers: I18n.t('react.shared.csv_log.list_type.payers'),
  orders: I18n.t('react.shared.csv_log.list_type.orders'),
  course_sessions: I18n.t('react.shared.csv_log.list_type.course_sessions'),
  payment_tickets: I18n.t('react.shared.csv_log.list_type.payment_tickets'),
  license_keys: I18n.t('react.shared.csv_log.list_type.license_keys'),
  embeddable_items: I18n.t('react.shared.csv_log.list_type.embeddable_items'),
  affiliate_programs: I18n.t('react.shared.csv_log.list_type.affiliate_programs'),
  affiliate_nodes: I18n.t('react.shared.csv_log.list_type.affiliate_nodes'),
  coupons: I18n.t('react.shared.csv_log.list_type.coupons'),
  product_groups: I18n.t('react.shared.csv_log.list_type.product_groups'),
  payouts: I18n.t('react.shared.csv_log.list_type.payouts'),
  invoices: I18n.t('react.shared.csv_log.list_type.invoices'),
  credit_memos: I18n.t('react.shared.csv_log.list_type.credit_memos'),
  opt_in_answers: I18n.t('react.shared.csv_log.list_type.opt_in_answers'),
  quiz_attempts: I18n.t('react.shared.csv_log.list_type.quiz_attempts'),
  financial_reports: I18n.t('react.shared.csv_log.list_type.financial_reports'),
}

export const SEPARATOR_OPTIONS = [
  {
    value: 'comma',
    label: I18n.t('react.shared.separator_type.comma'),
  },
  {
    value: 'dot',
    label: I18n.t('react.shared.separator_type.dot'),
  },
]

export const FREQUENCY_OPTIONS = [
  {
    value: 'monthly',
    label: I18n.t('react.shared.csv_modal.monthly'),
  },
  {
    value: 'weekly',
    label: I18n.t('react.shared.csv_modal.weekly'),
  },
]

export const DAY_FREQUENCY_LOCALES = {
  monthly: I18n.t('react.shared.csv_modal.monthly'),
  weekly: I18n.t('react.shared.csv_modal.weekly'),
}

export const FREQUENCY_TYPES = {
  weekly: 'weekly',
  monthly: 'monthly',
}

export const DAY_WEEKLY_OPTIONS = [
  {
    value: '1',
    label: I18n.t('react.shared.csv_modal.monday'),
  },
  {
    value: '2',
    label: I18n.t('react.shared.csv_modal.tuesday'),
  },
  {
    value: '3',
    label: I18n.t('react.shared.csv_modal.wednesday'),
  },
  {
    value: '4',
    label: I18n.t('react.shared.csv_modal.thursday'),
  },
  {
    value: '5',
    label: I18n.t('react.shared.csv_modal.friday'),
  },
  {
    value: '6',
    label: I18n.t('react.shared.csv_modal.saturday'),
  },
  {
    value: '7',
    label: I18n.t('react.shared.csv_modal.sunday'),
  },
]

export const DAY_MONTHLY_OPTIONS = [...Array(31).keys()].map((item) => {
  const englishLocale = PROFILE_LANGUAGE.en === (I18n.currentLocale ? I18n.currentLocale() : PROFILE_LANGUAGE.en)
  const number = item + 1
  const getLabel = (sequenceNumber) => `${number} ${englishLocale ? sequenceNumber : ''}`
  const option = {
    value: number,
    label: getLabel('th'),
  }
  switch (number) {
    case 1:
      option.label = getLabel('st')
      break
    case 2:
      option.label = getLabel('nd')
      break
    case 3:
      option.label = getLabel('rd')
      break
    default:
  }

  return option
})

export const DAY_WEEKLY_SHORT = {
  1: I18n.t('react.shared.csv_modal.mon'),
  2: I18n.t('react.shared.csv_modal.tue'),
  3: I18n.t('react.shared.csv_modal.wed'),
  4: I18n.t('react.shared.csv_modal.thu'),
  5: I18n.t('react.shared.csv_modal.fri'),
  6: I18n.t('react.shared.csv_modal.sat'),
  7: I18n.t('react.shared.csv_modal.sun'),
}

export const DAY_VALUES = DAY_MONTHLY_OPTIONS.reduce((obj, item) => {
  const label = { label: { [FREQUENCY_TYPES.monthly]: item?.label } }
  const weeklyValueShort = DAY_WEEKLY_SHORT[item.value]
  if (weeklyValueShort) {
    label.label[FREQUENCY_TYPES.weekly] = weeklyValueShort
  }
  return {
    ...obj,
    [item['value']]: label,
  }
}, {})

export const CSV_MODAL_TABS_KEYS = {
  download: 'download',
  scheduled: 'scheduled',
}

export const TAB_TITLES = [
  {
    key: CSV_MODAL_TABS_KEYS.download,
    label: I18n.t('react.shared.csv_modal.download_report'),
  },
  {
    key: CSV_MODAL_TABS_KEYS.scheduled,
    label: I18n.t('react.shared.csv_modal.scheduled_reports'),
  },
]

export const CSV_REPORT_MODE_KEYS = {
  downloadReport: 'downloadReport',
  scheduledReports: 'scheduledReports',
  createReport: 'createReport',
  schedulingReport: 'schedulingReport',
}

export const getCsvReportModes = (logsCsvLink) => ({
  downloadReport: {
    csvReportTitle: I18n.t('react.shared.csv_modal.csv_reports'),
    csvReportSubTitle: I18n.t('react.shared.csv_reports_modal_body', { logsLink: logsCsvLink }),
    csvReportButtonSubmitText: I18n.t('react.shared.csv_modal.create_csv_report'),
  },
  scheduledReports: {
    csvReportTitle: I18n.t('react.shared.csv_modal.csv_reports'),
    csvReportSubTitle: I18n.t('react.shared.csv_modal.with_scheduled_reports'),
    csvReportButtonSubmitText: I18n.t('react.shared.csv_modal.new_schedule'),
    csvReportButtonSubmitIcon: <i className='fas fa-calendar-check' />,
  },
  createReport: {
    csvReportTitle: I18n.t('react.shared.csv_modal.create_scheduled_report'),
    csvReportTitleSecond: I18n.t('react.shared.csv_modal.edit_scheduled_report'),
    csvReportSubTitle: I18n.t('react.shared.csv_modal.next_step'),
    csvReportButtonSubmitText: I18n.t('react.shared.button.continue'),
  },
  schedulingReport: {
    csvReportTitle: I18n.t('react.shared.csv_modal.select_scheduling_interval'),
    csvReportTitleSecond: I18n.t('react.shared.csv_modal.edit_scheduling_interval'),
    csvReportSubTitle: I18n.t('react.shared.csv_modal.choose_here'),
    csvReportButtonSubmitText: I18n.t('react.shared.csv_modal.schedule'),
    csvReportButtonSubmitTextSecond: I18n.t('react.shared.button.save'),
    csvReportButtonCentered: true,
  },
})
