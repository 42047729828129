import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable, toJS, makeObservable } from 'mobx'
import Creatable from 'react-select/creatable'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { isString } from 'utils/validators.utils'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import DropdownIndicator from './SelectDropdownIndicator'
import ClearIndicator from './SelectClearIndicator'

const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ),
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  valid: PropTypes.bool,
  required: PropTypes.bool,
  cashSelectedOption: PropTypes.bool,
}

const defaultProps = {
  searchable: true,
  isClearable: false,
  valid: true,
  required: false,
  cashSelectedOption: true,
}

@observer
class CreatableSelectFieldContainer extends Component {
  @observable showIsRequiredMessage = false

  onFieldChange = (options) => {
    const { required, onChange } = this.props
    const formattedOptions = options.map((option) => option.value)

    if (required) {
      this.showIsRequiredMessage = false
    }

    onChange(formattedOptions)
  }

  onBlur = () => {
    const { value, required } = this.props
    if (!value && required) {
      this.showIsRequiredMessage = true
    }
  }

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  render() {
    const {
      value = [],
      className,
      label,
      searchable,
      valid,
      errorMessage,
      isClearable,
      placeholder = this.props.I18n.t('react.shared.select.placeholder'),
      disabled,
      options,
      classNamePrefix,
      name,
      containerClassName,
      creatableFilter,
      I18n,
    } = this.props

    const formattedValues = options.reduce((result, option) => {
      const hasExactMatch = !!options.find(({ value }) => value === option.value)

      const checkExactMatches = isString(value) ? value === option.value : value.some((item) => item === option.value)

      const shouldPushOption = hasExactMatch ? checkExactMatches : value.includes(option.value)

      return shouldPushOption ? [...result, option] : result
    }, [])

    const convertedValue = toJS(value)
    const arrValue = Array.isArray(convertedValue) ? convertedValue : [convertedValue]

    const creatableFilterValues =
      value.length &&
      arrValue.reduce(
        (result, item) => [
          ...result,
          {
            value: item,
            label: item,
          },
        ],
        []
      )

    const fieldContainerClassNames = classNames('field elo-select-container', containerClassName, {
      'field--disabled': disabled,
    })
    const fieldClassNames = classNames('elo-select-field', className, {
      'elo-select-field__creatable_filter': creatableFilter,
      searchable,
      error: this.showIsRequiredMessage || !valid,
    })
    const fieldClassNamePrefix = classNames(classNamePrefix || 'elo-select-field')

    return (
      <div className={fieldContainerClassNames}>
        {label && (
          <label className='field__label' htmlFor={name}>
            <span>{label}</span>
          </label>
        )}

        {!valid && (
          <div className='field__error'>
            {errorMessage}
            <i className='fas fa-exclamation-circle' />
          </div>
        )}
        {this.showIsRequiredMessage && (
          <div className='field__error'>
            {I18n.t('react.shared.validations.required')}
            <i className='fas fa-exclamation-circle' />
          </div>
        )}
        <Creatable
          components={{
            ClearIndicator,
            DropdownIndicator,
          }}
          isMulti
          onChange={this.onFieldChange}
          value={creatableFilter ? creatableFilterValues : formattedValues}
          valueKey='tags'
          classNamePrefix={fieldClassNamePrefix}
          className={fieldClassNames}
          isDisabled={disabled}
          isSearchable={searchable}
          isClearable={isClearable}
          formatCreateLabel={(userInput) => `${I18n.t('react.shared.button.add')} ${userInput}`}
          menuPlacement='auto'
          noOptionsMessage={() => ''}
          {...{
            options,
            placeholder,
          }}
        />
      </div>
    )
  }
}

CreatableSelectFieldContainer.displayName = 'CreatableSelectField'
CreatableSelectFieldContainer.propTypes = propTypes
CreatableSelectFieldContainer.defaultProps = defaultProps

const CreatableSelectField = (props) => {
  const I18n = useI18n()

  return <CreatableSelectFieldContainer I18n={I18n} {...props} />
}

export default CreatableSelectField
