import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { formatDateTime } from '@elo-kit/utils/dateTime.utils'
import { createId } from '@elo-kit/utils/general.utils'
import { CSV_LOG_STATUSES_LABEL, SELLER_LIST_TYPE_LABEL } from 'constants/csvLogs.constants'

import EloTable from 'ui/elo-table/EloTable'
import TableSortLabelWrap from 'ui/TableSortLabelWrap'
import { EloTooltipMore } from '@elo-kit/components/elo-ui/elo-tooltip-more/EloTooltipMore'
import { I18nContext } from '@elo-kit/components/i18n/i18n'
import { RadioField } from '@elo-kit/components/form/radio-field/RadioField'

const getTableHead = (I18n) => [
  {
    label: '',
  },
  {
    label: I18n.t('react.cabinet.common.id'),
    sortingKey: 'id',
  },
  {
    label: I18n.t('react.cabinet.logs.tables.filename'),
  },
  {
    label: I18n.t('react.common.type'),
    sortingKey: 'list_type',
  },
  {
    label: I18n.t('react.shared.parameters'),
  },
  {
    label: 'Query params',
  },
  {
    label: 'State',
  },
  {
    label: I18n.t('react.shared.created'),
    sortingKey: 'created_at',
  },
]

@inject('csvLogsStore')
@observer
class CsvLogPreview extends Component {
  static contextType = I18nContext

  render() {
    const {
      csvLogsStore,
      csvLogsStore: { list },
      selectedCsvLog,
      updateSelectedCsvLog,
    } = this.props
    const I18n = this.context

    const getTableBody = list.map((item) => {
      const { createdAt, id, listType, state, csvParams, queryParams } = item

      return (
        <tr key={id}>
          <td>
            <RadioField id={id} value={(selectedCsvLog || {}).id} onChange={() => updateSelectedCsvLog(item)} />
          </td>
          <td className='elo-table__title'>{id}</td>
          <td>{`${listType?.toLowerCase()}-csv-${formatDateTime(createdAt)}`}</td>
          <td>{SELLER_LIST_TYPE_LABEL[listType]}</td>
          <td>
            <EloTooltipMore placement='bottom' id={createId(id, 'csvParams')}>
              {JSON.stringify(csvParams)}
            </EloTooltipMore>
          </td>
          <td>
            <EloTooltipMore placement='bottom' id={createId(id, 'queryParams')}>
              {JSON.stringify(queryParams)}
            </EloTooltipMore>
          </td>
          <td>{CSV_LOG_STATUSES_LABEL[state]}</td>
          <td>{formatDateTime(createdAt)}</td>
        </tr>
      )
    })

    return (
      <EloTable initStore={csvLogsStore} filtersList={['createdAt']} tableWithRadios>
        <EloTable.Head>
          <TableSortLabelWrap store={csvLogsStore} list={getTableHead(I18n)} />
        </EloTable.Head>
        <EloTable.Body>{getTableBody}</EloTable.Body>
      </EloTable>
    )
  }
}

export default CsvLogPreview
