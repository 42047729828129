import React, { PureComponent } from 'react'
import ReactPaginate from 'react-paginate'
import { observer } from 'mobx-react'
import { observable, computed, makeObservable } from 'mobx'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { I18nContext } from '@elo-kit/components/i18n/i18n'
import { SelectField } from '@elo-kit/components/form/select-field/SelectField'

import {
  DEFAULT_PAGE,
  ITEMS_PER_PAGE_OPTIONS,
  MARGIN_PAGES_DISPALAY,
  PAGE_RANGE_DISPLAY,
  PAGE_SMALL_RANGE_DISPLAY,
  MOBILE_SCREEN_WIDTH,
} from 'constants/pagination.constants'

import './_pagination.scss'

const propTypes = {
  pageToGoField: PropTypes.bool,
  itemsNumberField: PropTypes.bool,
  showItemsPerPageField: PropTypes.bool,
  showItemsPerPageOptions: PropTypes.array,
  perPageKey: PropTypes.string,
  initStore: PropTypes.shape({}),
}

const defaultProps = {
  pageToGoField: false,
  itemsNumberField: false,
  showItemsPerPageField: false,
  showItemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
  perPageKey: 'per',
  initStore: {},
}

@observer
class Pagination extends PureComponent {
  @observable pageToGo = ''

  static contextType = I18nContext

  constructor(props) {
    super(props)
    makeObservable(this)

    this.state = {
      isMobile: false,
    }
  }

  @computed get submittedPageToGo() {
    return Number(this.pageToGo) || DEFAULT_PAGE
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= MOBILE_SCREEN_WIDTH })
  }

  handleGoToPageChange = (page) => {
    const { initStore } = this.props
    const totalCount = initStore.pagination.total
    const perPage = initStore.pagination.per
    const pageCount = Math.ceil(totalCount / perPage)

    if (page === '') {
      this.pageToGo = page
    } else if (page < DEFAULT_PAGE) {
      this.pageToGo = DEFAULT_PAGE
    } else if (page > pageCount) {
      this.pageToGo = pageCount
    } else {
      this.pageToGo = page
    }
  }

  scrollToRef = () => {
    const { refToScroll, scrollToCurrent } = this.props
    const parentOffsetTop = refToScroll?.current?.offsetParent?.offsetTop

    if (parentOffsetTop && !scrollToCurrent) {
      window.scrollTo(0, parentOffsetTop)
    } else if (refToScroll?.current) {
      refToScroll.current.scrollIntoView()
    }
  }

  render() {
    const {
      initStore,
      initStore: { pagination = {} },
      showItemsPerPageOptions,
      itemsNumberField,
      showItemsPerPageField,
      pageToGoField,
      perPageKey,
      selectedLabelIds,
    } = this.props
    const I18n = this.context

    const { page, per: perPage, total: totalCount } = pagination

    const pageCount = Math.ceil(totalCount / perPage)
    const lastPage = page === pageCount
    const countItemsOnLastPage = totalCount % perPage
    const lastItemOnPage = lastPage ? totalCount : page * perPage
    const firstItemOnPage = lastPage ? totalCount - (countItemsOnLastPage || perPage) + 1 : lastItemOnPage - perPage + 1
    const correctedPageNumber = page - 1
    const itemPerPageMinValue =
      showItemsPerPageField &&
      showItemsPerPageOptions.reduce((min, option) => {
        const perPageOption = Number(option.value)
        return perPageOption < min ? perPageOption : min
      }, showItemsPerPageOptions[0]).value

    const showItemsCount = itemsNumberField && totalCount > Number(showItemsPerPageOptions[0].value)

    const nextLinkClasses = 'elo-pagination__link elo-pagination__link--next'
    const navigationDisable = showItemsPerPageField
      ? pageCount <= 1 && totalCount <= itemPerPageMinValue
      : pageCount <= 1

    const paginationDisable = showItemsPerPageField
      ? pageCount <= 1 && totalCount < itemPerPageMinValue
      : pageCount <= 1

    const paginationContainerClasses = classNames('elo-pagination-container', {
      'elo-pagination-container--navigation-only': !itemsNumberField,
      'elo-pagination-container--disabled': paginationDisable && navigationDisable,
    })
    const pageNavigationClasses = classNames('elo-pagination-container__pages-navigation', {
      'elo-pagination-container__pages-navigation--disabled': itemsNumberField && navigationDisable,
    })

    if (totalCount === 0) {
      return null
    }

    return (
      <div className={paginationContainerClasses}>
        {showItemsCount && (
          <div className='elo-pagination-container__items-number'>
            {`${firstItemOnPage}-${lastItemOnPage} `}
            {I18n.t('react.common.pagination.itemsOf')}
            {totalCount}
            {initStore.isTotalCountOverflowed ? '+' : ''}
          </div>
        )}

        <div className={pageNavigationClasses}>
          {showItemsPerPageField && (
            <div className='elo-show-items elo-select-container'>
              <div className='elo-show-items__label'>{I18n.t('react.common.pagination.show')}</div>
              <SelectField
                useObjectValue
                options={showItemsPerPageOptions}
                onChange={(per) => {
                  this.scrollToRef()
                  initStore.handlePaginationChange(perPageKey, Number(per.value), true, { selectedLabelIds })
                }}
                value={perPage?.toString()}
                className='select-display-top'
                searchable={false}
                isClearable={false}
                placeholder={perPage?.toString()}
              />
            </div>
          )}

          {!paginationDisable && (
            <ReactPaginate
              pageClassName='elo-pagination__page'
              activeClassName='elo-pagination__page elo-pagination__page--active'
              pageLinkClassName='elo-pagination__link'
              activeLinkClassName='elo-pagination__link elo-pagination__link--active'
              breakClassName='elo-pagination__page'
              breakLinkClassName='elo-pagination__link'
              previousLabel={<i className='fas fa-angle-left' />}
              nextLabel={<i className='fas fa-angle-right' />}
              previousClassName='elo-pagination__page'
              nextClassName='elo-pagination__page'
              previousLinkClassName={nextLinkClasses}
              nextLinkClassName={nextLinkClasses}
              containerClassName='elo-pagination'
              pageCount={pageCount}
              pageRangeDisplayed={this.state.isMobile ? PAGE_SMALL_RANGE_DISPLAY : PAGE_RANGE_DISPLAY}
              marginPagesDisplayed={MARGIN_PAGES_DISPALAY}
              onPageChange={(page) => {
                this.scrollToRef()
                initStore.handlePaginationChange('page', ++page.selected, true, { selectedLabelIds })
              }}
              forcePage={correctedPageNumber}
            />
          )}

          {pageToGoField && !paginationDisable && (
            <div className='elo-page-to-go-container'>
              <div className='elo-page-to-go-container__label'>{I18n.t('react.common.pagination.page')}</div>

              <input
                type='number'
                id='pageToGo'
                name='pageToGo'
                className='elo-page-to-go-container__input elo-page-to-go-container__input--page '
                value={this.pageToGo}
                onChange={(e) => this.handleGoToPageChange(e.target.value)}
              />

              <button
                onClick={() => {
                  this.scrollToRef()
                  initStore.handlePaginationChange('page', this.submittedPageToGo, true, { selectedLabelIds })
                }}
                className='elo-page-to-go-container__button'
              >
                {I18n.t('react.common.go')}
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

Pagination.displayName = 'Pagination'
Pagination.propTypes = propTypes
Pagination.defaultProps = defaultProps

export default Pagination
