import React, { Component } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { InfoTooltip } from '@elo-kit/components/info-tooltip/InfoTooltip'

import { AUTOCOMPLETE_OFF } from 'constants/forms.constants'

import './textarea-field.scss'
import { action, makeObservable, observable } from 'mobx'

const defaultProps = {
  autoComplete: AUTOCOMPLETE_OFF,
  disabled: false,
  rows: 6,
  validationOnUpdate: true,
  skipValidationOnUpdate: false,
}

interface Props extends React.TextareaHTMLAttributes<any> {
  label?: string
  isInvalid?: boolean
  validationOnUpdate?: (...args) => any
  skipValidationOnUpdate?: boolean
  unsetHeight?: boolean
  withoutResize?: boolean
  height120?: boolean
  errorMessage?: string
  field?: any
  form?: any
  formId?: any
  tooltipId?: string
  tooltipTitle?: string
  controlClassName?: string
  multilinePlaceholder?: boolean
  onChange?: (value: any) => any
}

@observer
class TextareaField extends Component<Props> {
  static displayName = 'TextareaField'
  static defaultProps = defaultProps

  @observable invalid = Boolean(this.props.validationOnUpdate ? false : this.props.isInvalid)

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidUpdate() {
    const { isInvalid, skipValidationOnUpdate } = this.props

    if (!skipValidationOnUpdate && isInvalid !== this.invalid) {
      this.setInvalid(isInvalid)
    }
  }

  @action setInvalid = (value: boolean | undefined) => (this.invalid = value)

  @action textFieldChange = ({ target }) => {
    const { onChange, required, isInvalid } = this.props
    const trimmedValue = target.value.trim()

    const invalid = isInvalid || (required && !trimmedValue)
    this.setInvalid(invalid)

    onChange(trimmedValue)
  }

  render() {
    const {
      autoComplete,
      className,
      disabled,
      errorMessage,
      field,
      form,
      formId,
      isInvalid,
      label,
      name,
      onChange,
      placeholder,
      required,
      rows,
      tooltipId,
      tooltipTitle,
      value,
      unsetHeight,
      withoutResize,
      height120,
      controlClassName,
      multilinePlaceholder,
      validationOnUpdate,
      skipValidationOnUpdate,
      ...props
    } = this.props

    const fieldClassNames = classNames('field textarea-field', className, {
      'field--disabled': disabled,
      'field--required': required,
    })

    const fieldControlClassNames = classNames(
      'field__control textarea-field__control',
      {
        'textarea-field__control--error': this.invalid,
        'textarea-field__control--unset-height': unsetHeight,
        'textarea-field__control--without-resize': withoutResize,
        'textarea-field__control--height-120': height120,
        'textarea-field__control--multiline-placeholder': multilinePlaceholder,
      },
      controlClassName
    )

    const handleChange = onChange ? ({ target }) => onChange(target.value) : field && field.onChange

    const errorText = errorMessage || (required && I18n.t('react.shared.validations.required'))

    const tooltipTitles = {
      meta_description: I18n.t('react.cabinet.help_icon.meta_description.title'),
    }

    const tooltipContent = {
      multiple_items_sharing_description: I18n.t('react.cabinet.help_icon.multiple_items_sharing_description.content'),
      select_box: I18n.t('react.cabinet.help_icon.select_box.content'),
      reseller_request_open: I18n.t('react.cabinet.help_icon.reseller_request_open.content'),
      reseller_request_close: I18n.t('react.cabinet.help_icon.reseller_request_close.content'),
      meta_description: I18n.t('react.cabinet.help_icon.meta_description.content'),
      // prettier-ignore
      mobile_app_custom_push_notification_content: I18n.t('react.cabinet.help_icon.mobile_app_custom_push_notification_content.content'),
      buyer_list_id: I18n.t('react.cabinet.help_icon.buyer_list_id.content'),
      buyer_campaign_id: I18n.t('react.cabinet.help_icon.buyer_campaign_id.content'),
      body: I18n.t('react.cabinet.help_icon.body.content'),
      tracify_cs_id: I18n.t('react.cabinet.help_icon.tracify_cs_id.content'),
      tracify_api_key: I18n.t('react.cabinet.help_icon.tracify_api_key.content'),
      tracify_is_staging: I18n.t('react.cabinet.help_icon.tracify_is_staging.content'),
      course_themes_css: I18n.t('react.cabinet.help_icon.course_themes_css.content'),
    }

    return (
      <div className={fieldClassNames}>
        {label && (
          <label className='field__label' htmlFor={name}>
            <span>{label}</span>
            {tooltipId && (
              <InfoTooltip
                id={`${name}_popover`}
                title={tooltipTitle ? tooltipTitles[tooltipId] : ''}
                body={tooltipContent[tooltipId]}
              />
            )}
          </label>
        )}

        {this.invalid && (
          <div className='field__error'>
            {errorText}
            <i className='fas fa-exclamation-circle' />
          </div>
        )}

        <textarea
          className={fieldControlClassNames}
          form={formId}
          id={name}
          onBlur={this.textFieldChange}
          onChange={handleChange}
          value={value || (field && field.value) || ''}
          {...{
            ...props,
            name,
            placeholder,
            rows,
            autoComplete,
          }}
        />
      </div>
    )
  }
}

export default TextareaField
