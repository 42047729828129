import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { EloButton } from '@elo-ui/components/elo-button'
import { EloTooltip } from '@elo-ui/components/elo-tooltip'
import { DEFAULT_PAGE } from 'constants/pagination.constants'

import { getSearchParams } from 'utils/queryString.utils'
import { sortFunction } from 'utils/helpers.utils'

import { EloTag } from '@elo-ui/components/elo-tag'
import { useCabinetStores } from '../../containers/cabinet/hooks/use-cabinet-stores'

import './filter_grouping.scss'

interface LabelButtonProps {
  toggleCreationLabelModal: (id?: number) => void
  selectedLabelIds: number[]
  setSelectedLabelIds: (id: number) => void
  label: {
    id: number
    name: string
    description: string
    productsCount: number
  }
}

interface Props {
  toggleCreationLabelModal: (id?: number) => void
  selectedLabelIds: number[]
  setSelectedLabelIds: (labelValue: number | number[], isCustomValue?: boolean) => void
  applySelectedLabels: (params: { page: number }) => void
}

const LabelButton: React.FC<LabelButtonProps> = (props) => {
  const { label, selectedLabelIds, setSelectedLabelIds, toggleCreationLabelModal } = props

  const productCounts = label.productsCount ? `(${label.productsCount})` : null

  return (
    <EloTooltip content={label.description} placement='bottom' hideDelay={0} disabled={!label.description}>
      <EloTag
        type='selectable'
        size='medium'
        isSelected={selectedLabelIds.includes(label.id)}
        value={label.id.toString()}
        onClick={(value) => setSelectedLabelIds(Number(value))}
        isEditable={true}
        onEdit={() => toggleCreationLabelModal(label.id)}
        iconSize={16}
      >
        <span className='label-options__tag-content'>{label.name}</span>
        <span className='label-options__tag-content label-options__tag-content--number'>{productCounts}</span>
      </EloTag>
    </EloTooltip>
  )
}

export const FilterGrouping: React.FC<Props> = observer((props) => {
  const { toggleCreationLabelModal, selectedLabelIds, setSelectedLabelIds, applySelectedLabels } = props

  const { labelsStore } = useCabinetStores()
  const [isOpenLabel, setIsOpenLabel] = useState(false)

  if (labelsStore.loading) {
    return null
  }

  const getSortedOption = (option) => option.sort((a, b) => sortFunction(a, b, 'name'))

  const getOptions = () => {
    const pinnedLabelOptions = []
    const unpinnedLabelOptions = []

    labelsStore.list.forEach((label) => {
      if (label.isPriority) {
        pinnedLabelOptions.push(label)
      } else {
        unpinnedLabelOptions.push(label)
      }
    })

    return {
      pinned: getSortedOption(pinnedLabelOptions),
      unpinned: getSortedOption(unpinnedLabelOptions),
    }
  }

  const labelTitle =
    selectedLabelIds.length === 1 ? I18n.t('react.cabinet.common.label') : I18n.t('react.shared.labels')

  const handleCloseLabel = () => {
    const labelIdsKey = 'labelIds[]'
    const labelIds = getSearchParams()[labelIdsKey]

    if (labelIds?.length) {
      const isLabelString = typeof labelIds === 'string'
      const labelValue = isLabelString ? [Number(labelIds)] : labelIds.map(Number)

      setSelectedLabelIds(labelValue, true)
    } else {
      setSelectedLabelIds([], true)
    }

    setIsOpenLabel(false)
  }

  return (
    <>
      {labelsStore.list.length ? (
        <div className='filter-grouping'>
          <div className='filter-grouping__container' onClick={() => setIsOpenLabel(true)}>
            <div className='filter-grouping__container--label'>
              {!!selectedLabelIds.length
                ? `${selectedLabelIds.length} ${labelTitle}`
                : I18n.t('react.cabinet.filter_grouping.filter_by_label')}
            </div>
            <div className='filter-grouping__container--icon'>
              <i className='fas fa-sort-down' />
            </div>
          </div>

          {isOpenLabel && (
            <>
              <div className='label-options'>
                <div className='label-options__container'>
                  <div className='label-options__pinned'>
                    {getOptions().pinned.map((label) => (
                      <LabelButton
                        key={label.id}
                        label={label}
                        selectedLabelIds={selectedLabelIds}
                        setSelectedLabelIds={setSelectedLabelIds}
                        toggleCreationLabelModal={toggleCreationLabelModal}
                      />
                    ))}
                  </div>

                  {!!getOptions().pinned.length && <div className='label-options__separator' />}

                  <div className='label-options__unpinned'>
                    {getOptions().unpinned.map((label) => (
                      <LabelButton
                        key={label.id}
                        label={label}
                        selectedLabelIds={selectedLabelIds}
                        setSelectedLabelIds={setSelectedLabelIds}
                        toggleCreationLabelModal={toggleCreationLabelModal}
                      />
                    ))}
                  </div>
                </div>

                <div className='label-options__button-positions'>
                  <EloButton
                    size='medium'
                    variant='secondary'
                    onClick={() => {
                      toggleCreationLabelModal()
                    }}
                  >
                    {I18n.t('react.shared.button.label_create')}
                  </EloButton>
                  <div className='label-options__button-positions--right-block'>
                    <EloButton size='medium' onClick={handleCloseLabel} variant='secondary'>
                      {I18n.t('react.shared.button.close')}
                    </EloButton>
                    <EloButton
                      size='medium'
                      onClick={() => {
                        applySelectedLabels({ page: DEFAULT_PAGE })
                        setIsOpenLabel(false)
                      }}
                    >
                      {I18n.t('react.shared.button.apply')}
                    </EloButton>
                  </div>
                </div>
              </div>

              <div className='label-options-close' onClick={handleCloseLabel} />
            </>
          )}
        </div>
      ) : (
        <EloTooltip content={I18n.t('react.shared.create_label.tooltip_info')} placement='bottom'>
          <EloButton onClick={() => toggleCreationLabelModal()} variant='secondary'>
            {I18n.t('react.shared.button.create_label')}
          </EloButton>
        </EloTooltip>
      )}
    </>
  )
})
