export const TIME_FILTER_KEYS = {
  threeDays: 'threeDays',
  oneWeek: 'oneWeek',
  twoWeeks: 'twoWeeks',
  yesterday: 'yesterday',
  today: 'today',
  allTime: 'allTime',
}

export const TIME_FILTER_OPTIONS = [
  {
    value: TIME_FILTER_KEYS.threeDays,
    label: I18n.t('react.shared.filter.threeDays'),
  },
  {
    value: TIME_FILTER_KEYS.oneWeek,
    label: I18n.t('react.shared.filter.oneWeek'),
  },
  {
    value: TIME_FILTER_KEYS.twoWeeks,
    label: I18n.t('react.shared.filter.twoWeeks'),
  },
  {
    value: TIME_FILTER_KEYS.yesterday,
    label: I18n.t('react.shared.filter.yesterday'),
  },
  {
    value: TIME_FILTER_KEYS.today,
    label: I18n.t('react.shared.filter.today'),
  },
  {
    value: TIME_FILTER_KEYS.allTime,
    label: I18n.t('react.shared.filter.allTime'),
  },
]
