import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import { observable, action, runInAction, makeObservable } from 'mobx'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { createId } from '@elo-kit/utils/general.utils'
import { debounce, trimStart } from 'utils/helpers.utils'
import { formatSelectCustomOptions } from 'utils/selectOptions.utils'
import { deepObjectPropNamesToCamelCase } from 'utils/nameStyle.utils'

import { DEFAULT_PAGE } from 'constants/pagination.constants'
import { SEARCH_DELAY } from 'constants/search.constants'
import { SIZES } from '@elo-kit/constants/general.constants'
import { LoadingSpinner } from '@elo-kit/components/loading-spinner/LoadingSpinner'
import { TextField } from '@elo-kit/components/elo-ui/text-field/TextField'
import { useI18n } from '@elo-kit/components/i18n/i18n'

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initStore: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  formatSelectOptions: PropTypes.func,
  defaultOptions: PropTypes.arrayOf(PropTypes.shape({})),
  errorMessage: PropTypes.string,
  isInvalid: PropTypes.bool,
  useCash: PropTypes.bool,
  validationOnUpdate: PropTypes.bool,
  queryParamName: PropTypes.string,
  skipInitialRequest: PropTypes.bool,
  searchLocally: PropTypes.bool,
}

const defaultProps = {
  defaultOptions: [],
  disabled: false,
  required: false,
  formatSelectOptions: formatSelectCustomOptions,
  useCash: true,
  validationOnUpdate: true,
  isInvalid: false,
  queryParamName: 'query',
  skipInitialRequest: false,
}

@observer
class EloAsyncSelectContainer extends Component {
  @observable open = false
  @observable options = []
  @observable cachedOptions = []
  @observable cachedTotalCount = 0
  @observable searchValue = ''
  @observable selectedItem = {
    value: '',
    label: '',
  }
  @observable isSearching = false

  loadOptionsWithDelay = debounce((value) => this.loadOptions(value), SEARCH_DELAY)

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { initStore, formatSelectOptions, value, defaultOptions, expands, skipInitialRequest = false } = this.props

    if (expands) {
      initStore.setExpands(expands)
    }

    if (value && !skipInitialRequest) {
      initStore.fetchItem(value).then(({ data, success }) => {
        if (success) {
          runInAction(() => {
            this.selectedItem = formatSelectOptions([deepObjectPropNamesToCamelCase(data)])[0] || {
              value: '',
              label: '',
            }
          })
        }
      })
    }
    if (defaultOptions) runInAction(() => (this.options = [...defaultOptions]))
  }

  componentWillUnmount() {
    const { initStore, expands } = this.props
    if (expands) {
      initStore.resetExpands()
    }
  }

  @action setIsSearching = (value = false) => (this.isSearching = value)

  @action handleSelectOpen = () => {
    const {
      initStore,
      initStore: { pagination },
      formatSelectOptions,
      defaultOptions,
      useCache,
      disabled,
      requestParams,
    } = this.props
    this.open = !this.open
    if (this.open && !disabled && (!useCache || !this.cachedOptions.length)) {
      pagination.page = DEFAULT_PAGE
      initStore.fetchList(requestParams).then(({ data }) => {
        const { list, totalCount } = data
        const formattedOptions = [...defaultOptions, ...formatSelectOptions(list)]
        this.options = formattedOptions
        this.cachedOptions = formattedOptions
        this.cachedTotalCount = totalCount
      })
    } else {
      this.searchValue = ''
      this.options = [...this.cachedOptions]
    }
  }

  @action loadOptions = (value = '') => {
    const { initStore, formatSelectOptions, requestParams, queryParamName, searchLocally = false } = this.props

    if (value && searchLocally && initStore.list) {
      this.options = formatSelectOptions(initStore.list).filter(
        (item) =>
          item.value.toString().toLowerCase().includes(value.toLowerCase()) ||
          item.label.toLowerCase().includes(value.toLowerCase())
      )
      this.setIsSearching(false)
    } else {
      initStore
        .fetchList({
          [queryParamName]: value,
          page: DEFAULT_PAGE,
          ...requestParams,
        })
        .then(({ data = {} }) => {
          this.options = formatSelectOptions(data?.list)
          this.setIsSearching(false)
        })
    }
  }

  @action handleLoadMode = () => {
    const {
      initStore: { pagination, fetchList },
      formatSelectOptions,
      requestParams,
      queryParamName,
    } = this.props
    pagination.page += 1

    fetchList({
      [queryParamName]: this.searchValue,
      ...requestParams,
    }).then(({ data }) => {
      this.options = [...this.options, ...formatSelectOptions(data.list)]
    })
  }

  handleItemSelect = (value) => {
    const { onChange } = this.props
    onChange(value)
    this.selectedItem = this.options.find((option) => option.value == value) || {
      value: '',
      label: '',
    }
    this.handleSelectOpen()
  }

  @action handleSearch = (inputValue) => {
    const trimmedValue = trimStart(inputValue)
    this.open = true
    if (this.searchValue !== trimmedValue) {
      this.searchValue = trimmedValue
      if (this.searchValue.length) {
        this.setIsSearching(true)
        this.loadOptionsWithDelay(this.searchValue, this.setIsSearching)
      } else {
        this.options = this.cachedOptions
      }
    }
  }

  render() {
    const {
      className,
      disabled,
      initStore,
      label,
      placeholder,
      required,
      tooltipId,
      tooltipTitle,
      value = '',
      isClearable,
      isInvalid,
      errorMessage,
      validationOnUpdate,
      formatListItem,
      filteredOptions,
      formatSelectOptions,
      I18n,
    } = this.props
    const { loading, pagination, list = [] } = initStore
    const selectedItem = [...this.options, this.selectedItem].find((option) => option.value == value) || {}
    const selectedListItemLabel = (
      formatSelectOptions(list).find((option) => String(option.value) === String(value)) || {}
    ).label

    const totalItems = this.searchValue.length ? pagination.total : this.cachedTotalCount
    const showLoadMore = !!this.options.length && totalItems > this.options.length && !loading && !filteredOptions
    const showNoOptions = !loading && !this.options.length

    const selectClasses = classNames('elo-select', className, {
      'elo-select--open': this.open,
    })

    const dropdowIndicatorClasses = classNames(
      'elo-select__icon-container elo-select__icon-container--dropdown-indicator',
      {
        'elo-select__icon-container--with-trash-bucket': !!this.selectedItem.value && isClearable,
      }
    )

    return (
      <Fragment>
        <div className={selectClasses}>
          <div className='elo-select__search' onClick={this.handleSelectOpen}>
            <TextField
              label={label}
              placeholder={selectedItem.label || selectedListItemLabel || placeholder}
              value={this.searchValue}
              onChange={(inputValue) => this.handleSearch(inputValue)}
              onBlur={() => {}}
              isInvalid={isInvalid}
              errorMessage={errorMessage}
              validationOnUpdate={validationOnUpdate}
              autocomplete='off'
              darkValue={Boolean(value)}
              {...{
                disabled,
                required,
                tooltipId,
                tooltipTitle,
              }}
            />
            {!!this.selectedItem.value && isClearable && (
              <div className='elo-select__icon-container elo-select__icon-container--trash-bucket'>
                <i className='far fa-trash-alt' onClick={() => this.handleItemSelect('')} />
              </div>
            )}
            <div className={dropdowIndicatorClasses}>
              <i className={this.open && !disabled ? 'fas fa-sort-up' : 'fas fa-sort-down'} />
            </div>
          </div>

          <div className='elo-select__list-container'>
            {this.open && !disabled && (
              <div className='elo-select__list'>
                {this.isSearching && <LoadingSpinner size={SIZES.extraSmall} />}

                {!this.isSearching &&
                  this.options.map(
                    ({ label, value, disabled = false }, index) =>
                      !disabled && (
                        <div
                          key={createId(value, index)}
                          onClick={() => this.handleItemSelect(value)}
                          className='elo-select__list-item'
                        >
                          {formatListItem ? formatListItem(label) : label}
                        </div>
                      )
                  )}

                {!this.isSearching && showLoadMore && (
                  <div onClick={this.handleLoadMode} className='elo-select__list-item elo-select__loading'>
                    {I18n.t('react.shared.load_more')}
                  </div>
                )}

                {showNoOptions && <div className='elo-select__no-options'>{I18n.t('shared.common.no_options')}</div>}

                {!this.isSearching && loading && <LoadingSpinner size={SIZES.extraSmall} />}
              </div>
            )}
          </div>
        </div>

        {this.open && <div className='elo-select__close' onClick={this.handleSelectOpen} />}
      </Fragment>
    )
  }
}

EloAsyncSelectContainer.displayName = 'EloAsyncSelect'
EloAsyncSelectContainer.propTypes = propTypes
EloAsyncSelectContainer.defaultProps = defaultProps

const EloAsyncSelect = (props) => {
  const I18n = useI18n()

  return <EloAsyncSelectContainer I18n={I18n} {...props} />
}

export default EloAsyncSelect
