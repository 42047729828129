import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { observable, action, computed, makeObservable } from 'mobx'

import { I18nContext } from '@elo-kit/components/i18n/i18n'

import { TEXT_FIELD_AUTOCOMPLETE } from 'constants/forms.constants'
import { SEARCH_MIN_LENGTH } from 'constants/search.constants'

import './search-field.scss'

const propTypes = {
  className: PropTypes.string,
  autocomplete: PropTypes.oneOf([TEXT_FIELD_AUTOCOMPLETE.on, TEXT_FIELD_AUTOCOMPLETE.off]),
  disabled: PropTypes.bool,
  minSearchLength: PropTypes.number,
}

const defaultProps = {
  autocomplete: TEXT_FIELD_AUTOCOMPLETE.off,
  disabled: false,
  minSearchLength: SEARCH_MIN_LENGTH,
}

@observer
class SearchField extends Component {
  static contextType = I18nContext

  @observable searchValue = this.props.initStore.query

  @action
  setSearchValue = (value) => (this.searchValue = value)

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const searchNode = document.getElementById(this.searchFieldId)
    searchNode.addEventListener('keydown', this.handleKeyPress)
  }

  componentDidUpdate() {
    const { initStore } = this.props
    if (initStore.reseted.search) {
      this.setSearchValue(initStore.query)
      initStore.toggleReseted('search')
    }
  }

  componentWillUnmount() {
    const searchNode = document.getElementById(this.searchFieldId)
    searchNode && searchNode.removeEventListener('keydown', this.handleKeyPress)
  }

  @computed get searchFieldId() {
    const { initStore } = this.props
    return initStore ? `search-field-${initStore.constructor.name}` : 'search-field'
  }

  handleChange = (event) => {
    event.preventDefault()
    this.setSearchValue(event.target.value)
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleSearch(event, this.searchFieldId)
    }
  }

  handleSearch = (event, fieldId) => {
    const {
      initStore: { handleQuerySearch, query },
      minSearchLength,
    } = this.props

    if (query !== this.searchValue) {
      if (this.searchValue.length >= minSearchLength || this.searchValue === '') {
        fieldId ? handleQuerySearch(this.searchValue, fieldId) : handleQuerySearch(this.searchValue)
      }
    }
  }

  render() {
    const { className, autocomplete, initStore, disabled } = this.props
    const I18n = this.context

    const searchDisabled = initStore.loading || disabled
    const fieldClassNames = classNames('field search-field', { 'field--disabled': searchDisabled }, className)

    return (
      <div className={fieldClassNames}>
        <div className='search-field__input-wrapper' id='search-field-wrapper'>
          <input
            id={this.searchFieldId}
            type='input'
            className='field__control search-field__control'
            placeholder={I18n.t('shared.common.search')}
            autoComplete={autocomplete}
            onChange={this.handleChange}
            disabled={searchDisabled}
            value={this.searchValue}
            onKeyDown={this.handleKeyPress}
            onBlur={this.handleSearch}
          />
          <i className='fas fa-search search-field__icon' />
        </div>
      </div>
    )
  }
}

SearchField.displayName = 'SearchField'
SearchField.propTypes = propTypes
SearchField.defaultProps = defaultProps

export default SearchField
