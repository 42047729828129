import React, { Component } from 'react'

class TableSortLabel extends Component {
  getSortableClasses = () => {
    const { active, direction } = this.props

    if (active) {
      if (direction.toLowerCase() === 'asc') {
        return 'fa-caret-up'
      } else {
        return 'fa-caret-down'
      }
    } else {
      return 'fa-sort'
    }
  }

  render() {
    const { className, colSpan, label, onClick } = this.props

    return (
      <th className={`sortable ${className || ''}`} onClick={onClick} colSpan={colSpan}>
        <div className='sortable__container'>
          {label}
          <i className={`fas ml-1 ${this.getSortableClasses()}`} />
        </div>
      </th>
    )
  }
}
export default TableSortLabel
