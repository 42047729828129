import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import { observable, action, computed, toJS, makeObservable } from 'mobx'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import { EloButton } from '@elo-ui/components/elo-button'

import {
  DATE_RANGE_TYPE,
  DATE_RANGE_WITHOUT_TIME_TYPE,
  DATE_TIME_RANGE_TYPE,
  SELECT_TYPE,
  TEXT_INPUT_TYPE,
  NUMBER_INPUT_TYPE,
  FILTERS_LIST,
  SELECT_WITH_SEARCH_TYPE,
  DATE_INPUT_TYPE,
  NUMBER_RANGE_TYPE,
  SELECT_TYPE_WITH_MULTIPLE,
  SELECT_WITH_MULTIPLE_SEARCH_TYPE,
  SELECT_WITH_MULTIPLE_CREATABLE_TYPE,
  DATE_RELATIVE_RANGE_TYPE,
  CREATED_RELATIVE_PERIOD_OPTIONS,
  CREATED_RELATIVE_DAY_TIME_OPTIONS,
  CREATED_RELATIVE_RANGE_OPTIONS,
  CREATED_RELATIVE_PERIOD_KEYS,
} from 'constants/filter.constants'

import { DATE_FORMATS, TIME_FORMATS } from '@elo-kit/constants/dateTime.constants'
import { NumberField } from '@elo-kit/components/form/number-field/NumberField'
import { CheckboxField } from '@elo-kit/components/form/checkbox-field/CheckboxField'
import { SelectField } from '@elo-kit/components/form/select-field/SelectField'
import { TextField } from '@elo-kit/components/elo-ui/text-field/TextField'
import { DateTimeField } from '@elo-kit/components/form/date-time-field/DateTimeField'
import { FloatNumberField } from '@elo-kit/components/form/float-number-field/FloatNumberField'
import { I18nContext } from '@elo-kit/components/i18n/i18n'
import MultiAsyncSelectField from '@elo-kit/components/form/select/MultiAsyncSelectField'
import EloAsyncSelect from '@elo-kit/components/form/select/EloAsyncSelect'
import CreatableSelectField from '@elo-kit/components/form/select/CreatableSelectField'
import MultiSelectField from '@elo-kit/components/form/select/MultiSelectField'
import { createId } from '@elo-kit/utils/general.utils'

import { isPrevAndNextFiltersEqual } from 'utils/filter.utils'

import './_filter.scss'

const propTypes = {
  filtersList: PropTypes.arrayOf(PropTypes.string),
  fetchOptionsStores: PropTypes.shape({}),
  keys: PropTypes.shape({
    from: PropTypes.string,
    till: PropTypes.string,
  }),
}

const defaultProps = {
  keys: {
    from: 'from',
    till: 'till',
  },
}

@observer
class Filter extends Component {
  static contextType = I18nContext

  @observable filterIsOpen = false
  @observable filterEnabledItems = {}
  @observable filterAppliedItems = {}

  @observable selectSearchFilterValues = {}

  initFilters = () => {
    this.handleFilterReset()
    const { filtersList, initStore } = this.props
    const { filters: queryStringFilters } = initStore || {}

    const usedFilters = FILTERS_LIST.filter(({ key }) => filtersList?.find((filterKey) => filterKey === key))

    queryStringFilters?.forEach((value, key) => {
      const appliedFilter = usedFilters.find(({ serverKey, boundedKeyValues }) => {
        let found = false

        if (typeof serverKey === 'object') {
          found = serverKey[key] === key
        } else {
          found = serverKey === key
        }

        if (found && boundedKeyValues) {
          found = Object.keys(boundedKeyValues).every(
            (boundedKey) => queryStringFilters.get(boundedKey) === boundedKeyValues[boundedKey]
          )
        }

        return found
      })

      if (appliedFilter) {
        this.setFilterEnabledItems({
          ...this.filterEnabledItems,
          [appliedFilter.key]: !!value,
        })
        this.setFilterAppliedItems({ ...this.filterEnabledItems })

        if (appliedFilter.type === SELECT_WITH_SEARCH_TYPE) {
          this.setSelectSearchFilterValue(appliedFilter.key, value)
        }
      }
    })
  }

  @action setFilterAppliedItems = (value) => (this.filterAppliedItems = value)
  @action setFilterEnabledItems = (value) => (this.filterEnabledItems = value)

  onFilterChange = (serverKey, value) => {
    const {
      initStore: { handleFilterChange, setPrevFilters },
    } = this.props
    handleFilterChange(serverKey, value) //
    setPrevFilters()
  }

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    this.initFilters()
  }

  componentDidUpdate(prevProps) {
    const {
      initStore: { prevFilters },
    } = prevProps
    const {
      initStore: { filters, setPrevFilters },
      withSavedFilters,
    } = this.props

    if (withSavedFilters) {
      const isFiltersEqual = isPrevAndNextFiltersEqual(prevFilters, filters)
      if (!isFiltersEqual) {
        this.initFilters()
        setPrevFilters()
      }
    } else {
      this.handleFilterReset()
    }
  }

  @action handleFilterReset = () => {
    const { reseted, toggleReseted } = this.props.initStore || {}

    if (reseted?.filter) {
      this.setFilterEnabledItems({})
      this.setFilterAppliedItems({})
      this.selectSearchFilterValues = {}

      toggleReseted('filter')
    }
  }

  @action setSelectSearchFilterValue = (key, value) => {
    this.selectSearchFilterValues = {
      ...this.selectSearchFilterValues,
      [key]: value,
    }
  }

  @action filterToggle = () => {
    this.filterIsOpen = !this.filterIsOpen
  }

  @action toggleFilterEnabledItem = ({ key, serverKey, type, value }) => {
    const { filters, handleFilterChange } = this.props.initStore

    const newState = !this.filterEnabledItems[key]
    this.setFilterEnabledItems({
      ...this.filterEnabledItems,
      [key]: newState,
    })
    if (newState) {
      if (
        type !== DATE_RANGE_TYPE &&
        type !== DATE_INPUT_TYPE &&
        type !== DATE_RELATIVE_RANGE_TYPE &&
        type !== DATE_RANGE_WITHOUT_TIME_TYPE
      ) {
        handleFilterChange(serverKey, value)
      }
    } else {
      if (type === DATE_TIME_RANGE_TYPE) {
        filters.delete(serverKey.from_time)
        filters.delete(serverKey.till_time)
        filters.delete(serverKey.activated_from)
        filters.delete(serverKey.activated_till)
        filters.delete(serverKey.success_from)
        filters.delete(serverKey.success_till)
      }
      if (type === NUMBER_RANGE_TYPE) {
        filters.delete(serverKey.from)
        filters.delete(serverKey.to)
      }
      if (type === DATE_RANGE_TYPE || type === DATE_RANGE_WITHOUT_TIME_TYPE) {
        filters.delete(serverKey.from)
        filters.delete(serverKey.till)
      } else {
        filters.delete(serverKey)
      }
      if (type === SELECT_WITH_SEARCH_TYPE) {
        this.selectSearchFilterValues = {
          ...this.selectSearchFilterValues,
          [key]: null,
        }
      }
      if (type === DATE_RELATIVE_RANGE_TYPE) {
        filters.delete(serverKey[`${serverKey.key}_period`])
        filters.delete(serverKey[`${serverKey.key}_time`])
      }
    }
  }

  @action resetFilters = () => {
    const { applyFilters: customApplyFilters } = this.props
    const { applyFilters, resetFilters } = this.props.initStore

    this.filterToggle()
    this.setFilterEnabledItems({})
    this.setFilterAppliedItems({})
    this.selectSearchFilterValues = {}
    resetFilters()

    if (customApplyFilters) {
      customApplyFilters()
    } else {
      applyFilters()
    }
  }

  @action applyFilters = () => {
    const { applyFilters: customApplyFilters } = this.props
    this.filterToggle()
    this.setFilterAppliedItems({ ...this.filterEnabledItems })
    if (customApplyFilters) {
      customApplyFilters()
    } else {
      this.props.initStore.applyFilters()
    }
  }

  @computed get filterAppliedItemsCount() {
    const pms = toJS(this.filterAppliedItems)
    return Object.keys(pms).filter((key) => pms[key]).length
  }

  getTillStartDate = (current, serverKey) => {
    const fromDate = this.props.initStore.filters.get(serverKey)
    const formattedFromDate = fromDate && new Date(moment(fromDate).format()).toString()
    return !formattedFromDate || current.isSameOrAfter(formattedFromDate)
  }

  getFromEndDate = (current, serverKey) => {
    const tillDate = this.props.initStore.filters.get(serverKey)
    const formattedTillDate = tillDate && new Date(moment(tillDate).format()).toString()
    return !formattedTillDate || current.isSameOrBefore(formattedTillDate)
  }

  renderFilterItem = (filterItem) => {
    const { initStore, fetchOptionsStores, timezone } = this.props
    const {
      formatOptions,
      key,
      options,
      placeholder,
      serverKey,
      max,
      store,
      type,
      value,
      requestParams = {},
      skipInitialRequest,
      searchable,
      searchLocally,
      loadServerKey,
    } = filterItem
    const I18n = this.context

    const commonProps = {
      onChange: (value) => this.onFilterChange(serverKey, value),
      value: initStore.filters.get(serverKey),
    }

    const fetchOptionsStore = fetchOptionsStores ? fetchOptionsStores[store] : {}

    const createdRelativeDatePeriod = initStore.filters.get(serverKey[`${serverKey.key}_period`])
    const createdRelativeDateTime = initStore.filters.get(serverKey[`${serverKey.key}_time`])

    switch (type) {
      case DATE_TIME_RANGE_TYPE:
        return (
          <div className='elo-filter__date-range'>
            <DateTimeField
              displayTimeZone={timezone}
              formatToGet={DATE_FORMATS.default}
              placeholder={I18n.t('shared.common.from')}
              value={initStore.filters.get(serverKey[`${serverKey.key}_from_time`])}
              onChange={(value) =>
                initStore.handleFilterChange(serverKey[`${serverKey.key}_from_time`], moment(value).toISOString())
              }
              isValidDate={(current) => this.getFromEndDate(current, serverKey[`${serverKey.key}_till_time`])}
              timeFormat={TIME_FORMATS.HHmm}
            />
            <hr />
            <DateTimeField
              displayTimeZone={timezone}
              formatToGet={DATE_FORMATS.default}
              placeholder={I18n.t('shared.common.untill')}
              value={initStore.filters.get(serverKey[`${serverKey.key}_till_time`])}
              onChange={(value) => {
                const tillTime = initStore.filters.get(serverKey[`${serverKey.key}_till_time`])

                const momentTillTime = tillTime ? moment(tillTime, DATE_FORMATS.default) : ''
                const momentValue = moment(value, DATE_FORMATS.default)

                const diff = Math.abs(momentValue.diff(momentTillTime))

                if (Number.isNaN(diff)) {
                  momentValue.add(1, 'day').subtract(1, 'seconds')
                }

                const formattedValue = momentValue.toISOString()

                return initStore.handleFilterChange(serverKey[`${serverKey.key}_till_time`], formattedValue)
              }}
              isValidDate={(current) => this.getTillStartDate(current, serverKey[`${serverKey.key}_from_time`])}
              timeFormat={TIME_FORMATS.HHmm}
            />
          </div>
        )
      case DATE_RANGE_TYPE:
        return (
          <div className='elo-filter__date-range'>
            <DateTimeField
              placeholder={I18n.t('shared.common.from')}
              value={initStore.filters.get(serverKey.from)}
              onChange={(value) => this.onFilterChange(serverKey.from, value)}
              isValidDate={(current) => this.getFromEndDate(current, serverKey.till)}
            />
            <hr />
            <DateTimeField
              placeholder={I18n.t('shared.common.untill')}
              value={initStore.filters.get(serverKey.till)}
              onChange={(value) => this.onFilterChange(serverKey.till, value)}
              isValidDate={(current) => this.getTillStartDate(current, serverKey.from)}
            />
          </div>
        )
      case DATE_RANGE_WITHOUT_TIME_TYPE:
        return (
          <div className='elo-filter__date-range'>
            <DateTimeField
              placeholder={I18n.t('shared.common.from')}
              value={initStore.filters.get(serverKey.from)}
              onChange={(value) =>
                this.onFilterChange(serverKey.from, moment(value).format(DATE_FORMATS.NOT_SEPARATED_YYYYMMDD))
              }
              isValidDate={(current) => this.getFromEndDate(current, serverKey.till)}
            />
            <hr />
            <DateTimeField
              placeholder={I18n.t('shared.common.untill')}
              value={initStore.filters.get(serverKey.till)}
              onChange={(value) =>
                this.onFilterChange(serverKey.till, moment(value).format(DATE_FORMATS.NOT_SEPARATED_YYYYMMDD))
              }
              isValidDate={(current) => this.getTillStartDate(current, serverKey.from)}
            />
          </div>
        )
      case NUMBER_RANGE_TYPE: {
        const from = initStore.filters.get(serverKey.from)
        const to = initStore.filters.get(serverKey.to)
        return (
          <div className='elo-filter__number-range'>
            <FloatNumberField
              name={serverKey.from}
              placeholder={serverKey.placeholderFrom || `${I18n.t('shared.common.from')}, %`}
              value={from}
              max={max}
              disableOnBlur
              onChange={(value) => this.onFilterChange(serverKey.from, value)}
            />
            <FloatNumberField
              name={serverKey.to}
              value={to}
              onChange={(value) => this.onFilterChange(serverKey.to, value)}
              max={max}
              disableOnBlur
              placeholder={serverKey.placeholderTo || `${I18n.t('shared.common.till')}, %`}
            />
          </div>
        )
      }
      case SELECT_TYPE:
        return <SelectField name={key} options={options} defaultValue={value} {...commonProps} />
      case SELECT_TYPE_WITH_MULTIPLE:
        return (
          <MultiSelectField
            value={initStore.filters.get(serverKey)}
            options={options}
            onChange={(value) => this.onFilterChange(serverKey, value)}
          />
        )
      case SELECT_WITH_SEARCH_TYPE:
        return (
          <EloAsyncSelect
            initStore={fetchOptionsStore}
            value={this.selectSearchFilterValues[key]}
            onChange={(value) => {
              this.setSelectSearchFilterValue(key, value)
              this.onFilterChange(serverKey, value)
            }}
            formatSelectOptions={formatOptions}
            placeholder={I18n.t('react.shared.select_item')}
            className='elo-select--no-margin'
            requestParams={requestParams}
            skipInitialRequest={skipInitialRequest}
            searchLocally={searchLocally}
          />
        )

      case SELECT_WITH_MULTIPLE_SEARCH_TYPE:
        return (
          <MultiAsyncSelectField
            initStore={fetchOptionsStore}
            pagination={fetchOptionsStore?.pagination}
            loading={fetchOptionsStore?.loading}
            value={initStore.filters.get(serverKey)}
            onChange={(value) => this.onFilterChange(serverKey, value)}
            formatSelectOptions={formatOptions}
            placeholder={I18n.t('react.shared.select_item')}
            className='elo-select--no-margin'
            searchable={searchable}
            searchLocally={searchLocally}
            loadServerKey={loadServerKey}
          />
        )

      case SELECT_WITH_MULTIPLE_CREATABLE_TYPE:
        return (
          <CreatableSelectField
            value={initStore.filters.get(serverKey)}
            options={[]}
            onChange={(value) => {
              this.setSelectSearchFilterValue(key, value)
              this.onFilterChange(serverKey, value)
            }}
            placeholder={placeholder || I18n.t('react.shared.add_value_here')}
            creatableFilter
          />
        )
      case TEXT_INPUT_TYPE:
        return (
          <TextField name={key} placeholder={placeholder || I18n.t('react.shared.add_value_here')} {...commonProps} />
        )
      case NUMBER_INPUT_TYPE:
        return (
          <NumberField name={key} placeholder={placeholder || I18n.t('react.shared.add_value_here')} {...commonProps} />
        )
      case DATE_INPUT_TYPE:
        return <DateTimeField placeholder={placeholder} {...commonProps} />
      case DATE_RELATIVE_RANGE_TYPE:
        return (
          <div className='elo-filter__relative-date'>
            <SelectField
              value={createdRelativeDatePeriod}
              options={CREATED_RELATIVE_PERIOD_OPTIONS}
              onChange={(value) => this.onFilterChange(serverKey[`${serverKey.key}_period`], value)}
              placeholder={I18n.t('react.shared.select_item')}
            />
            {createdRelativeDatePeriod && (
              <SelectField
                key={createdRelativeDatePeriod}
                value={createdRelativeDateTime}
                options={
                  createdRelativeDatePeriod === CREATED_RELATIVE_PERIOD_KEYS.last
                    ? CREATED_RELATIVE_DAY_TIME_OPTIONS
                    : CREATED_RELATIVE_RANGE_OPTIONS
                }
                onChange={(value) => this.onFilterChange(serverKey[`${serverKey.key}_time`], value)}
                placeholder={I18n.t('react.shared.select_item')}
              />
            )}
          </div>
        )
      default:
        return
    }
  }

  renderFilterItems = () => {
    const { filtersList, initStore } = this.props
    const {
      createdAt,
      createdRelativeAt,
      transferCreatedAtTime,
      createdRelativeAtTime,
      successAtTime,
      successRelativeAtTime,
      successAt,
      singleOrderIdExportLines,
      multipleOrderIdExportLines,
    } = this.filterEnabledItems
    const filtersToShow = FILTERS_LIST.filter(({ key }) => filtersList.indexOf(key) >= 0)

    return filtersToShow.map((filterItem, index) => {
      const { key, label } = filterItem
      const filterEnabled = !!this.filterEnabledItems[key]

      const filterItemClasses = classNames('elo-filter__item', {
        'elo-filter__item--disabled': !filterEnabled,
      })

      const disabledObject = {
        createdAt: createdRelativeAt,
        createdRelativeAt: createdAt || successAt,
        transferCreatedAtTime: createdRelativeAtTime,
        createdRelativeAtTime: transferCreatedAtTime,
        successAtTime: successRelativeAtTime,
        successRelativeAtTime: successAtTime,
        successAt: createdRelativeAt,
        multipleOrderIdExportLines: singleOrderIdExportLines,
        singleOrderIdExportLines: multipleOrderIdExportLines,
      }

      return (
        <div className={filterItemClasses} key={createId(key, index)}>
          <CheckboxField
            id={key}
            name={key}
            label={label}
            checked={filterEnabled}
            className='elo-filter__checkbox'
            onChange={() => {
              this.toggleFilterEnabledItem(filterItem)
              initStore.setPrevFilters()
            }}
            disabled={disabledObject[key]}
          />
          {filterEnabled && this.renderFilterItem(filterItem)}
        </div>
      )
    })
  }

  render() {
    const { filtersList, initStore } = this.props
    const I18n = this.context

    const withTime =
      filtersList?.includes('activatedAtTime') ||
      filtersList?.includes('chargedAtTime') ||
      filtersList?.includes('createdAtTime') ||
      filtersList?.includes('firstOrderAtTime') ||
      filtersList?.includes('successAtTime')

    const filterClasses = classNames('elo-filter', {
      'elo-filter--with-time': withTime,
    })

    const filterCounterClasses = classNames('elo-filter-toggle__counter', {
      'elo-filter-toggle__counter--disabled': this.filterAppliedItemsCount === 0,
    })

    const filterToggleClasses = classNames('elo-filter-toggle', {
      'elo-filter-toggle--disabled': initStore?.loading,
    })

    const filterToggleButtonClasses = classNames('elo-filter-toggle__icon-container', {
      'elo-filter-toggle__icon-container--active': this.filterIsOpen,
    })

    return (
      <Fragment>
        <button
          id='buttonEnableFilter'
          type='button'
          className={filterToggleClasses}
          onClick={this.filterToggle}
          disabled={initStore?.loading}
        >
          <div className={filterToggleButtonClasses}>
            <i className='fas fa-filter elo-filter-toggle__filter-icon' />
            <i
              className={
                this.filterIsOpen
                  ? 'fas fa-sort-up elo-filter-toggle__arrow-up'
                  : 'fas fa-sort-down elo-filter-toggle__arrow-down'
              }
            />
          </div>
          <div className={filterCounterClasses}>{this.filterAppliedItemsCount}</div>
        </button>

        {this.filterIsOpen && (
          <Fragment>
            <div className={filterClasses}>
              <div>
                <div className='elo-filter__btn-container'>
                  <EloButton variant='secondary' size='small' onClick={this.resetFilters}>
                    {I18n.t('react.shared.button.clear')}
                  </EloButton>
                  <EloButton variant='primary' size='small' onClick={this.applyFilters}>
                    {I18n.t('react.shared.button.apply')}
                  </EloButton>
                </div>
                {this.renderFilterItems()}
              </div>
            </div>
            <div className='elo-filter-close' onClick={this.filterToggle} />
          </Fragment>
        )}
      </Fragment>
    )
  }
}

Filter.defaultProps = defaultProps
Filter.displayName = 'Filter'
Filter.propTypes = propTypes

export default Filter
