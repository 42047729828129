import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import { observable, action, computed, toJS, makeObservable } from 'mobx'
import { withRouter } from 'react-router'
import queryString from 'query-string'
import classNames from 'classnames'

import { profile } from 'utils/profileHelper.utils'
import { createId } from '@elo-kit/utils/general.utils'
import { isEmpty } from '@elo-kit/utils/validators.utils'
import { Ellipsis } from '@elo-kit/components/ellipsis/Ellipsis'
import { CustomTooltip } from '@elo-kit/components/elo-ui/custom-tooltip/CustomTooltip'
import { EloButton } from '@elo-kit/components/elo-button/EloButton'
import { SelectField } from '@elo-kit/components/form/select-field/SelectField'
import { TextField } from '@elo-kit/components/elo-ui/text-field/TextField'

import EloModal from 'ui/EloModal'
import DropdownIndicator from '@elo-kit/components/form/select/SelectDropdownIndicator'
import TextareaField from '@elo-kit/components/textarea-field/TextareaField'

import { BLACK_LIST_FILTER_PARAMS, FILTERS_KEYS_LIST } from 'constants/filter.constants'
import { POSITIONS } from '@elo-kit/constants/general.constants'

import { areObjectsEqual, descendingSort } from 'utils/helpers.utils'
import { camelToSnakeCase } from 'utils/nameStyle.utils'
import tableFiltersStore from '../../stores/tableFilters.store'
import './saved-filters.scss'

const CustomSelectOption = (props) => {
  const { componentsProps, data } = props
  const { toggleShowModal, setFormData, isSingleValue } = componentsProps
  const { deleteItem, fetchFullList, list } = tableFiltersStore
  const option = isSingleValue ? list.find((op) => op.id === data.value) : data
  const { id, key, ownerType, name, description } = toJS(option) || {}

  const editOption = (e) => {
    e.stopPropagation()
    setFormData({
      id,
      name,
      description,
    })
    toggleShowModal()
  }

  const deleteOption = async (e) => {
    e.stopPropagation()

    await deleteItem(id, {
      profileType: camelToSnakeCase(ownerType).toLowerCase(),
      key,
    })
    await fetchFullList()
  }

  const nameClasses = classNames('', {
    'fields-container--60-percentage': !isSingleValue,
  })

  return (
    <div className='fields-container'>
      <div className={nameClasses}>
        <Ellipsis maxLength={22}>{name || I18n.t('react.shared.select.placeholder')}</Ellipsis>
      </div>
      {!isSingleValue && (
        <div className='fields-container fields-container--30-percentage fields-container--right-align'>
          <CustomTooltip id={createId('saved-filter', String(id))} body={description} placement={POSITIONS.left} />
          <i className='fas fa-pencil-alt elo-btn-icon elo-btn-icon--grey' onClick={editOption} />
          <i className='far fa-trash-alt elo-btn-icon elo-btn-icon--grey' onClick={deleteOption} />
        </div>
      )}
    </div>
  )
}

const DEFAULT_FORM_DATA = {
  name: '',
  description: '',
  id: null,
}

@withRouter
@observer
class SavedFilters extends Component {
  @observable showModal = false
  @observable formData = DEFAULT_FORM_DATA
  @observable filterId = null

  constructor(props) {
    super(props)

    makeObservable(this)
  }

  @action toggleShowModal = () => (this.showModal = !this.showModal)

  @action setFormData = (value, name = null) => {
    if (name) {
      this.formData = {
        ...this.formData,
        [name]: value,
      }
    } else {
      this.formData = value
    }
  }

  @action setFilterId = (value) => (this.filterId = value)

  componentDidMount() {
    const { initStore } = this.props
    const { handleScopeChange, fetchFullList } = tableFiltersStore
    handleScopeChange('key', initStore.storeKey)
    handleScopeChange('profileType', profile.profileType)
    fetchFullList()
  }

  componentDidUpdate() {
    if (this.showSaveFilterButton) {
      this.setFilterId(null)
    }
  }

  @action setSavedFilter = (filterId) => {
    const {
      initStore: { handleFilterChange, applyFilters, setPrevFilters, resetFilters },
      fetchSummary,
    } = this.props
    const { list } = tableFiltersStore
    const filter = list.find((item) => item.id === filterId) || {}
    const paramsObject = queryString.parse(filter.params, { arrayFormat: 'bracket' })

    setPrevFilters()
    resetFilters()
    Object.entries(paramsObject).forEach(([key, value]) => handleFilterChange(key, value))
    this.setFilterId(filterId)
    applyFilters()

    if (fetchSummary) {
      fetchSummary()
    }
  }

  createFilter = async (data) => {
    const { createItem, fetchFullList } = tableFiltersStore
    await createItem(data)
    const {
      data: { list },
    } = await fetchFullList()
    if (list.length) {
      const firstFilterId = (descendingSort(list, 'id')[0] || {}).id
      this.setSavedFilter(firstFilterId)
    }
  }

  getObjectParams = (paramsString = '') => {
    const parsedParamsObject = queryString.parse(paramsString, { arrayFormat: 'bracket' })
    return Object.keys(parsedParamsObject)
      .filter((item) => !BLACK_LIST_FILTER_PARAMS.includes(item) && FILTERS_KEYS_LIST.includes(item))
      .reduce((obj, key) => {
        obj[key] = parsedParamsObject[key]
        return obj
      }, {})
  }

  @computed get showSaveFilterButton() {
    const {
      history: {
        location: { search },
      },
    } = this.props
    const { list } = tableFiltersStore
    const filter = list.find((item) => item.id === this.filterId) || {}
    return !areObjectsEqual(this.getObjectParams(search), this.getObjectParams(filter.params))
  }

  customFilter = (option, searchText = '') => {
    const {
      label: { props },
    } = option.data || {}
    const { name } = props.data
    const isSearchIncludesOptionName = name.toLowerCase().includes(searchText.toLowerCase())
    if (isSearchIncludesOptionName) {
      return true
    }
    return false
  }

  onCancel = () => {
    this.toggleShowModal()
    this.setFormData(DEFAULT_FORM_DATA)
  }

  render() {
    const {
      initStore,
      history: {
        location: { search },
      },
      responsive,
    } = this.props
    const { updateItem, list, fetchFullList } = tableFiltersStore
    const { id, ...commonFormData } = this.formData
    const { name, description } = commonFormData
    const filterObject = this.getObjectParams(search)

    const createData = {
      ...commonFormData,
      profileType: profile.profileType,
      key: initStore.storeKey,
      params: queryString.stringify(filterObject, { arrayFormat: 'bracket' }),
    }
    const { params, ...updateData } = createData

    const onSubmit = async () => {
      this.setFormData(DEFAULT_FORM_DATA)
      if (id) {
        await updateItem(id, updateData)
        await fetchFullList()
      } else {
        await this.createFilter(createData)
      }
    }
    const submitDisabled = !name.trim().length
    const componentsProps = {
      toggleShowModal: this.toggleShowModal,
      setFormData: this.setFormData,
    }

    const options = descendingSort(list, 'id').map((item) => ({
      value: item.id,
      label: (
        <CustomSelectOption
          {...{
            data: item,
            componentsProps,
          }}
        />
      ),
    }))

    const SingleValueContainer = (props) =>
      CustomSelectOption({
        ...props,
        componentsProps: {
          ...componentsProps,
          isSingleValue: true,
        },
      })

    const savedFiltersClasses = classNames('elo-select__saved-filters', {
      'elo-select__saved-filters--responsive': responsive,
    })

    return (
      <Fragment>
        {this.showSaveFilterButton && !isEmpty(filterObject) && (
          <EloButton onClick={this.toggleShowModal} className='rectangle--save-filter' rectangle>
            {I18n.t('react.shared.button.save')}
          </EloButton>
        )}
        {!!list.length && (
          <SelectField
            options={options}
            onChange={({ value }) => {
              this.setSavedFilter(value)
              this.setFilterId(value)
            }}
            value={this.filterId}
            components={{
              SingleValue: SingleValueContainer,
              DropdownIndicator,
            }}
            filterOption={this.customFilter}
            className={savedFiltersClasses}
            searchable
            useObjectValue
          />
        )}
        {this.showModal && (
          <EloModal
            isOpen={this.showModal}
            title={I18n.t('react.shared.table.modals.save_filter.title')}
            toggle={this.toggleShowModal}
            submit={onSubmit}
            submitDisabled={submitDisabled}
            onCancel={this.onCancel}
            onClose={this.onCancel}
          >
            <TextField
              label={I18n.t('react.shared.name')}
              placeholder={I18n.t('react.shared.name')}
              value={name}
              onChange={(value) => this.setFormData(value, 'name')}
              required
            />
            <TextareaField
              label={I18n.t('react.shared.description')}
              placeholder={I18n.t('react.shared.description')}
              value={description}
              onChange={(value) => this.setFormData(value, 'description')}
            />
          </EloModal>
        )}
      </Fragment>
    )
  }
}

export default SavedFilters
