import React, { Fragment } from 'react'

import TableSortLabel from 'ui/TableSortLabel'

import { createId } from '@elo-kit/utils/general.utils'

const TableSortLabelWrap = (props) => {
  const { disableSort, list, store } = props

  return (
    <Fragment>
      {list.map((item, index) => {
        const { hide, label, sortingKey } = item

        if (hide) {
          return null
        }

        const sortable = sortingKey && !disableSort

        return sortable ? (
          <TableSortLabel
            key={createId(sortingKey, index)}
            label={label}
            active={store.listSortingKey() === sortingKey}
            direction={store.listSortingDirection()}
            onClick={() => store.handleSort(sortingKey)}
          />
        ) : (
          <th key={index}>{label}</th>
        )
      })}
    </Fragment>
  )
}
export default TableSortLabelWrap
